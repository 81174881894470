import Flex from "components/common/Flex";

function calculateAge(birthDate) {
  if (!birthDate) return "-";
  if (birthDate === "") return "-";
  const today = new Date();
  const birth = new Date(birthDate);
  let age = today.getFullYear() - birth.getFullYear();
  const monthDiff = today.getMonth() - birth.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
    age--;
  }

  return age;
}

function AgeCalculator({ birthDate }) {
  const age = calculateAge(birthDate);

  return <Flex>{age}</Flex>;
}

export default AgeCalculator;
