import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import useDepsContainer from 'hooks/useDepsContainer';

const MetaThemeColor = () => {
  const location = useLocation();
  const { uiStore } = useDepsContainer();
  const [themeColor, setThemeColor] = useState('#FFFFFF');

  useEffect(() => {
    const { pathname } = location;
    getMetaColor(pathname, setThemeColor);
    const metaTag = document.querySelector('meta[name="theme-color"]');
    if (metaTag) {
      metaTag.setAttribute('content', themeColor);
    }
  }, [location, themeColor]);

  return null;
};

const getMetaColor = (pathname, setThemeColor) => {
  // 根据不同的路由路径返回对应的标题
  switch (pathname) {
    case '/':
    case '/terms':
    case '/cookie':
    case '/guidelines':
    case '/privacy':
    case '/login':
      setThemeColor('white');
      break;
    case '/mall':
    case '/register':
      setThemeColor('white');
      break;
    default:
      setThemeColor('white');
      break;
  }
};

export default MetaThemeColor;
