import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { AppTheme } from "styles/theme";
import { useHistory, useLocation } from "react-router-dom";
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import useDepsContainer from "hooks/useDepsContainer";
import Flex from "components/common/Flex";
import MallHeader from "screens/Mall/components/MallHeader";
import PageFooter from "components/Footer";
import { usernameList } from "api/malls";
import ToPayIcon from "screens/Payment/components/ToPayIcon";
import AccordionIcon from "screens/Payment/components/AccordionIcon";
import CreditCard from "screens/Payment/components/creditCard";
import PayPal from "screens/Payment/components/PayPal";
import { RoundLockSvg } from "assets/icons";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

interface PaymentPageProps {}

function PaymentPage(props: PaymentPageProps) {
  const styles = useStyles(props);
  const theme = useTheme();
  const history = useHistory();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const { uiStore } = useDepsContainer();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const RefInviter = searchParams.get("param");

  const [userInfo, setUserInfo] = useState<any>({});
  const [propInfo, setPropInfo] = useState<any>({});

  useEffect(() => {
    if (RefInviter) {
      const jsonString = atob(RefInviter)
      const base64String = JSON.parse(jsonString)
      setPropInfo(base64String)
    }
  }, [RefInviter]);

  useEffect(() => {

    usernameList({}).then((res) => {
      const data = res?.data;
      setUserInfo(data?.data || {});
    });
  }, []);

  function toMall() {
    history.push("/mall")
  }

  return (
    <Flex className={styles.bg}column centerY>
      <MallHeader userInfo={userInfo} />

      <Flex px={mdDown ? 4 : 0} mt={mdDown ? 2 : 0} width={mdDown ? "100vw" : 1000}>
        <Flex width={"100%"} display={"flex"} justifyContent={"space-between"} column={mdDown}>
          <Flex
            style={{
              color: "#232429",
              fontSize: mdDown ? "32px" : "40px",
              fontWeight: "700",
            }}
            onClick={toMall}
            centerY
            cursorPointer
          >
            <ArrowBackIosIcon fontSize={mdDown ? "medium" : "large"} />
            Check out
          </Flex>
          <Flex pt={mdDown ? 2 : 6} width={mdDown ? "100%" : "500px"} column>
            <Flex bgcolor={"#AAACBB"} style={{
              width: "100%",
              height: "4px",
              borderRadius: "20px",
            }}>
              <Flex bgcolor={"#E85130"} height={"4px"} borderRadius={"20px"} width={"1%"}></Flex>
            </Flex>
            <Flex mt={1} width={"100%"} display={"flex"} justifyContent={"space-between"} centerY>
              <Flex>Payment Method</Flex>
              <Flex>Complete</Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        px={mdDown ? 4 : 0}
        my={mdDown ? 4 : 7}
        width={mdDown ? "100vw" : 1000}
        display={"flex"}
        justifyContent={"space-between"}
        column={mdDown}
      >
        <Flex
          p={4}
          className={styles.card}
          borderRadius={"4px"}
          flex={1}
          column
        >
          <Flex mb={6} className={styles.h1}>
            Payment Method
          </Flex>
          <Flex>
            <AccordionIcon />
          </Flex>
          <Flex mt={2}>
            <CreditCard />
          </Flex>
          <Flex mt={2}>
            <PayPal />
          </Flex>
          <Flex mt={4} centerY>
            <RoundLockSvg />
            <Flex color={"#828282"} fontSize={12} ml={2}>
              We protect your payment information using encryption to provide
              bank-level security.
            </Flex>
          </Flex>
        </Flex>

        <Flex
          width={mdDown ? "100%" : 360}
          ml={mdDown ? 0 : 5}
          mt={mdDown ? 4 : 0}
          p={4}
          className={styles.card}
          borderRadius={"16px"}
          column
        >
          <ToPayIcon propInfo={propInfo} />
        </Flex>
      </Flex>

      <PageFooter />
    </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    bg: {
      width: "100vw",
      minHeight: "100vh",
      color: "black",
      overflowX: "hidden",
      backgroundColor: "#FBFBFB",
    },
    card: {
      boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.14)",
      background: "white",
      fontSize: "16px",
      fontWeight: 400,
    },
    h1: {
      fontSize: "16px",
      fontWeight: 700,
    },
    accordion: {
      border: "1px solid #B2BCCA",
      "&.MuiAccordion-root": {
        boxShadow: "none",
        borderRadius: "4px",
        padding: "12px",
      },
      "&.MuiAccordion-root.Mui-expanded": {
        boxShadow: "none",
        border: "1px solid #1660CF",
        backgroundColor: "rgba(22, 96, 207, 0.10)",
      },
      "& .MuiAccordionSummary-content": {
        margin: 0,
      },
      "&::before": {
        display: "none",
      },
    },
  })
);

export default observer(PaymentPage);
