import React, { useState, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { AppTheme } from "styles/theme";
import { MenuItem, Select, useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import Flex from "components/common/Flex";
import Text from "components/common/Text";
import i18n, { LangKeys } from "locales/i18n";
import ExpandMore from "@material-ui/icons/ExpandMore";
import useDepsContainer from "hooks/useDepsContainer";

interface LangSelectProps {
    SelectSx?: object;
    MenuItemSx?: object;
}

const languageOptions = [
    {
        key: "234213mumbaiasfdsa",
        name: "Mumbai",
    },
    {
        key: "32sdaf23423",
        name: "Ethereum",
    },
];

function LangSelect({ SelectSx, MenuItemSx }: LangSelectProps) {
    const styles = useStyles();
    const { uiStore } = useDepsContainer();

    const [iconName, setIconName] = useState("234213mumbaiasfdsa")

    const changeLanguage = (e) => {
        setIconName(e.target.value)
    };

    return (
        <>
            <Select
                value={iconName}
                variant="standard"
                defaultValue={"234213mumbaiasfdsa"}
                disableUnderline
                sx={Object.assign(
                    {},
                    SelectSx
                )}
                className={styles.select}
                onChange={changeLanguage}
                IconComponent={ExpandMore}
                SelectDisplayProps={{
                    style: {
                        maxWidth: 300,
                    },
                }}
                inputProps={{
                    style: {
                        maxWidth: 80,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                }}
            >
                {languageOptions.map((item, index) => (
                    <MenuItem value={item.key} key={item.key}>
                        <Flex centerY key={item.key}>
                            {/* {item.icon}&nbsp;&nbsp; */}
                            <Text
                                ml={1}
                                style={
                                    Object.assign(
                                        {},
                                        {
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        },
                                        MenuItemSx
                                    ) as any
                                }
                                className={styles.menuText}
                            >
                                {item.name}
                            </Text>
                        </Flex>
                    </MenuItem>
                ))}
            </Select>
        </>
    );
}

const useStyles = makeStyles((theme: AppTheme) =>
    createStyles({
        menuText: {
            fontFamily: "'Rubik' !important",
            fontStyle: "normal !important",
            fontWeight: "400 !important",
            fontSize: "18px !important",
            lineHeight: "155% !important",
            letterSpacing: "-0.03em !important",
            textTransform: "none",
        },
        select: {
            backgroundColor: "white",
            "&:after": {
                borderBottomColor: "white",
            },
            "& .MuiSvgIcon-root": {
                color: "white",
            },
        },
    })
);

export default observer(LangSelect);
