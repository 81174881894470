import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { AppTheme } from "styles/theme";
import { useHistory } from "react-router-dom";
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import useDepsContainer from "hooks/useDepsContainer";
import Flex from "components/common/Flex";
import Radio from "@material-ui/core/Radio";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PumpkinSvg } from "assets/icons";

function ToPayIcon({ propInfo }) {
  const styles = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const { uiStore } = useDepsContainer();

  const [loading, setLoading] = useState<any>(false);
  const [buyIcon, setBuyIcon] = useState<boolean>(true);

  function handleChange() {
    setBuyIcon(!buyIcon);
  }

  return (
    <>
      <Flex mb={6} className={styles.h1}>
        Order Review
      </Flex>
      <Flex
        bgcolor={"#F3F4FC"}
        style={{
          padding: "10px",
        }}
        centerY
      >
        <Flex mr={3} p={1} bgcolor={"black"}>
          <PumpkinSvg width={40} height={40} />
        </Flex>{" "}
        {propInfo?.num} Pumpkin
      </Flex>
      <Flex py={1} mt={3} className={styles.flex}>
        <Flex centerY>
          <PumpkinSvg width={20} height={20} />
          <b>{propInfo?.num}</b> Pumpkin
        </Flex>
        <Flex>
          <b>{propInfo?.price}</b> USDT
        </Flex>
      </Flex>
      <Flex mb={1} className={styles.flex}>
        <Flex centerY>Discount</Flex>
        <Flex>
          <b>0</b> USDT
        </Flex>
      </Flex>
      <Flex
        className={styles.flex}
        pt={1}
        pb={5}
        style={{
          border: "1px solid #DDDFEE",
          borderLeft: "none",
          borderRight: "none",
        }}
        centerY
      >
        <Flex>Total</Flex>
        <Flex alignItems={"flex-end"} column>
          <Flex>
            <b>{propInfo?.price}</b> USDT
          </Flex>
          <Flex>1USDT - 1000USDT</Flex>
        </Flex>
      </Flex>
      <Flex
        style={{
          fontSize: "14px",
          display: "flex",
        }}
      >
        <Flex>
          <Radio
            checked={buyIcon}
            onChange={handleChange}
            value="buyIcon"
            color="default"
            className={styles.root}
            checkedIcon={
              <span className={clsx(styles.icon, styles.checkedIcon)} />
            }
            icon={<span className={styles.icon} />}
            size="small"
          />
        </Flex>
        <Flex py={7} display={"inline-block"} className={styles.policy}>
          Please check to acknowledge our <i>Privacy</i> & <i>Terms Policy</i>
        </Flex>
      </Flex>
      <Flex>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          style={{
            boxShadow: "none",
            padding: "8px 0",
            borderRadius: "80px",
            fontSize: "16px",
            width: "100%",
            background: "#3B3C45",
          }}
        >
          {loading && <CircularProgress size={20} color={"inherit"} />}&nbsp;
          CONTINUE
        </Button>
      </Flex>
    </>
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    h1: {
      fontSize: "16px",
      fontWeight: 700,
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#232429",
      "& b": {
        display: "inline-block",
        margin: "0 3px",
      },
    },
    policy: {
      "& i": {
        fontStyle: "normal",
        color: "#2F88FF",
      },
    },

    root: {
      "&:hover": {
        backgroundColor: "white !important",
      },
    },
    icon: {
      borderRadius: "50%",
      width: 16,
      height: 16,
      boxShadow:
        "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      backgroundColor: "#f5f8fa",
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
      },
      "input:hover ~ &": {
        backgroundColor: "#ebf1f5",
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(206,217,224,.5)",
      },
    },
    checkedIcon: {
      backgroundColor: "#137cbd",
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 16,
        height: 16,
        backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: "#106ba3",
      },
    },
  })
);

export default observer(ToPayIcon);
