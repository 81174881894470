import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { AppTheme } from "styles/theme";
import { useHistory } from "react-router-dom";
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import useDepsContainer from "hooks/useDepsContainer";
import Flex from "components/common/Flex";
import Radio from "@material-ui/core/Radio";
import { BitcoinSvg, IconUSDCSvg, IconUSDTSvg } from "assets/icons";
import IconSelect from "./IconSelect";

export function AccordionIconTitle() {
  const theme = useTheme();
  const styles = useStyles();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [buyIcon, setBuyIcon] = useState<boolean>(true);

  function handleChange() {
    setBuyIcon(!buyIcon);
  }

  return (
    <Flex
      width={"100%"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"flex-start"}
      column={mdDown}
    >
      <Flex centerY>
        <Radio
          checked={buyIcon}
          onChange={handleChange}
          value="buyIcon"
          color="default"
          className={styles.root}
          checkedIcon={
            <span className={clsx(styles.icon, styles.checkedIcon)} />
          }
          icon={<span className={styles.icon} />}
          size="small"
        />
        Crypto
      </Flex>
      <Flex px={mdDown ? 0 : 7} py={mdDown ? 2 : 0} color={"#4F4F4F"}>
        You will be redirected to the wallet connection after submitting your
        order
      </Flex>
      <Flex>
        <BitcoinSvg />
      </Flex>
    </Flex>
  );
}

function AccordionIcon() {
  const styles = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const { uiStore } = useDepsContainer();

  const [iconType, setIconType] = useState<any>("USDT");

  function btnClickType(type: string) {
    setIconType(type);
  }

  return (
    <Flex className={styles.piece} width={"100%"} px={3} py={3} column>
      <AccordionIconTitle />
      <Flex my={6} className={styles.flex} column={mdDown}>
        <Flex>Network</Flex>
        <Flex>
          <IconSelect
            SelectSx={{
              borderRadius: "4px",
              width: "300px",
              height: "36px",
              fontSize: "18px",
              fontWeight: "400",
              border: "1px solid #AAACBB",
              "& .MuiSvgIcon-root": {
                color: "black",
              },
            }}
            MenuItemSx={{}}
          />
        </Flex>
      </Flex>
      <Flex className={styles.flex} column={mdDown}>
        <Flex>Pay with</Flex>
        <Flex>
          <Flex mr={4}>
            <Button
              className={iconType === "USDT" ? styles.bntActive : styles.bnt}
              variant="contained"
              onClick={() => btnClickType("USDT")}
              disableElevation
            >
              <IconUSDTSvg /> <Flex ml={1}>USDT</Flex>
            </Button>
          </Flex>
          <Flex>
            <Button
              className={iconType === "USDC" ? styles.bntActive : styles.bnt}
              variant="contained"
              onClick={() => btnClickType("USDC")}
              disableElevation
            >
              <IconUSDCSvg />
              <Flex ml={1}>USDC</Flex>
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    h1: {},
    flex: {
      display: "flex",
      justifyContent: "space-between",
    },
    piece: {
      border: "1px solid #1660CF",
      borderRadius: "4px",
      background: "rgba(22, 96, 207, 0.10)",
    },
    bnt: {
      border: "1px solid #AAACBB",
      "&.MuiButton-root": {
        color: "black",
        backgroundColor: "white",
        border: "1px solid #AAACBB",
        borderRadius: "8px",
        "&:hover": {
          color: "white",
          fontWeight: 700,
          backgroundColor: "#3B3C45",
        },
      },
    },
    bntActive: {
      border: "1px solid #AAACBB",
      "&.MuiButton-root": {
        color: "white",
        fontWeight: 700,
        backgroundColor: "#3B3C45",
        border: "1px solid #AAACBB",
        borderRadius: "8px",
        "&:hover": {
          color: "white",
          fontWeight: 700,
          backgroundColor: "#3B3C45",
        },
      },
    },

    root: {
      padding: "9px 0 !important",
      paddingRight: "9px !important",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      borderRadius: "50%",
      width: 16,
      height: 16,
      boxShadow:
        "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      backgroundColor: "#f5f8fa",
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
      },
      "input:hover ~ &": {
        backgroundColor: "#ebf1f5",
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(206,217,224,.5)",
      },
    },
    checkedIcon: {
      backgroundColor: "#137cbd",
      backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 16,
        height: 16,
        backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: "#106ba3",
      },
    },
  })
);

export default observer(AccordionIcon);
