import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GoogleAnalytics from "./GoogleAnalytics";
import { PrivyProvider } from '@privy-io/react-auth'
import "./index.css";



ReactDOM.render(
  <React.StrictMode>
    <GoogleAnalytics />
    <PrivyProvider
        appId={process.env.PRIVY_APP_ID || 'clvav2h8v0bz010csvi0dqzpn'}
        config={{
          embeddedWallets: {
            createOnLogin: 'users-without-wallets',
          },
        }}
      >
      <App />
    </PrivyProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
