import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { AppTheme } from "styles/theme";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { BitcoinSvg, PumpkinSvg } from "assets/icons";
import Flex from "components/common/Flex";

interface MallCardProps {
  width: string;
  item: any;
  toPayment: any
}

function MallCard(props: MallCardProps) {
  const styles = useStyles(props);
  const theme = useTheme();
  const history = useHistory();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const down900 = useMediaQuery(theme.breakpoints.down(900));
  const down513 = useMediaQuery(theme.breakpoints.down(513));

  const { width, item, toPayment } = props;

  function DefaultCard () {
    return (
        <Flex
            mb={10}
            py={5}
            px={5}
            className={styles.card}
            onClick={toPayment}
            bgcolor={"black"}
            width={width}
        >
          <Flex>
            <PumpkinSvg width={140} height={124} />
          </Flex>
          <Flex alignItems={"flex-end"} column>
            <Flex>
              <Flex pl={1}>
                <BitcoinSvg />
              </Flex>
              {/*<Flex pl={1}>*/}
              {/*  <PaypalSvg />*/}
              {/*</Flex>*/}
              {/*<Flex pl={1}>*/}
              {/*  <VisaSvg />*/}
              {/*</Flex>*/}
            </Flex>
            <Flex className={styles.info01} my={2} color={"#30E8BC"}>
              {item.label.toUpperCase() || `0% OFF`}
            </Flex>
            <Flex className={styles.info02}>{item?.num || 0} PUMPKIN</Flex>
            <Flex className={styles.price} mt={8}>
              {item?.price || 0} USD
            </Flex>
          </Flex>
        </Flex>
    )
  }

  function MoveCard() {
    return (
        <Flex
            mb={5}
            px={2}
            py={2}
            className={styles.moveCard}
            onClick={toPayment}
            bgcolor={"black"}
            width={width}
            center
            column
        >
          <Flex width={"100%"} display={"flex"} pb={2} justifyContent={"space-between"} style={{
            borderBottom: "1px solid #232429"
          }}>

            <Flex pt={down900 ? down513 ? 5 : 0 : 5}>
              <PumpkinSvg width={down900 ? down513 ? 40 : 105 : 40} height={down900 ? down513 ? 40 : 105 : 40} />
            </Flex>

            <Flex pt={down900 ? down513 ? 0 : 3 : 0} width={"65%"} column>
              <Flex>
                <Flex>
                  <BitcoinSvg />
                </Flex>
              </Flex>
              <Flex className={styles.moveInfo01} my={2} color={"#30E8BC"}>
                {item.label.toUpperCase() || `0% OFF`}
              </Flex>
              <Flex className={styles.moveInfo02}>{item?.num || 0} PUMPKIN</Flex>
            </Flex>
          </Flex>

          <Flex className={styles.movePrice} pt={2}>
            {item?.movePrice || 0} USD
          </Flex>
        </Flex>
    )
  }

  return (
    mdDown ? <MoveCard /> : <DefaultCard />
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    card: {
      boxShadow: "2px 4px 12px rgba(0, 0, 0, 0.08)",
      borderRadius: "18px",
      display: "flex",
      justifyContent: "space-between",
      color: "white",
      border: "2px solid black",
      cursor: "pointer",
      "&:hover": {
        borderColor: "#E85130",
        boxShadow: "2px 2px 12px #FD746C",
      },
    },
    moveCard: {
      boxShadow: "2px 4px 12px rgba(0, 0, 0, 0.08)",
      borderRadius: "18px",
      display: "flex",
      justifyContent: "space-between",
      color: "white",
      border: "2px solid black",
      cursor: "pointer",
      "&:hover": {
        borderColor: "#E85130",
        boxShadow: "2px 2px 12px #FD746C",
      },
    },
    info01: {
      fontSize: "14px",
      fontWeight: 400,
    },
    info02: {
      fontSize: "18px",
      fontWeight: 700,
      letterSpacing: "-0.2px",
    },
    moveInfo01: {
      fontSize: "10px",
      fontWeight: 400,
    },
    moveInfo02: {
      fontSize: "14px",
      fontWeight: 700,
    },
    price: {
      fontSize: "24px",
      fontWeight: 700,
      letterSpacing: "-0.5px",
    },
    movePrice: {
      fontSize: "18px",
      fontWeight: 700,
      letterSpacing: "-0.2px",
    },
  })
);

export default observer(MallCard);
