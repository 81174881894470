import { useEffect } from "react";
import { useLocation } from "react-router";
import useDepsContainer from "hooks/useDepsContainer";
import { autorun } from "mobx";
import HOME from "./meta/HOME";
import MALL from "./meta/MALL";
import PRIVACY from "./meta/PRIVACY";
import TERMS from "./meta/TERMS";
import COOKIE from "./meta/COOKIE";
import GUIDELINES from "./meta/GUIDELINES";
import REGISTER from "./meta/REGISTER";

const TitleUpdater = () => {
  const location = useLocation();
  const { uiStore } = useDepsContainer();

  useEffect(() => {
    const { pathname } = location;
    const dispose = autorun(() => {
      const title = getTitleFromPathname(pathname, uiStore.language);
      document.title = title;
    });
    return () => {
      dispose();
    };
  }, [location, uiStore.language]);

  return null;
};

const getTitleFromPathname = (pathname, lang) => {
  // 根据不同的路由路径返回对应的标题
  switch (pathname) {
    case "/":
      return HOME?.[lang]?.title || HOME.en.title;
    case "/mall":
      return MALL?.[lang]?.title || MALL.en.title;
    case "/privacy":
      return PRIVACY.en.title;
    case "/terms":
      return TERMS.en.title;
    case "/cookie":
      return COOKIE.en.title;
    case "/guidelines":
      return GUIDELINES.en.title;
    case "/register":
      return REGISTER?.[lang]?.title || REGISTER.en.title;
    default:
      return HOME?.[lang]?.title || HOME.en.title;
  }
};

export default TitleUpdater;
