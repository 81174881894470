import { useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import Flex from "components/common/Flex";
import { observer } from "mobx-react";
import { AppTheme } from "styles/theme";
import RegisterForm from "../Auth/components/RegisterForm";
import { AuthLogo2Svg, LogoTextSvg } from "assets/icons";

interface RegisterPageProps {}

const RegisterPage = (props: RegisterPageProps) => {
  const styles = useStyles(props);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Flex width="100vw" height={"100vh"}>
      <Flex
        width="100vw"
        height={"100vh"}
        className={styles.pageContainer}
        column={mdDown}
        bgcolor={"white"}
      >
        <Flex
          center
          className={mdDown ? styles.sessionWrapMdDown : styles.sessionWrap}
        >
          <RegisterForm />
        </Flex>
        {!mdDown && (
          <Flex
            bgcolor={"#FEF3F1"}
            className={mdDown ? styles.sessionWrapMdDown : styles.sessionWrap}
            center
          >
            <Flex column center style={{ position: "relative" }}>
              <AuthLogo2Svg />
              <Flex
                style={{
                  position: "absolute",
                  width: "100%",
                  left: 0,
                  bottom: "80px",
                }}
                center
              >
                <LogoTextSvg style={{ width: 230 }} />
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    pageContainer: {
      overflowX: "hidden",
      overflowY: "auto",
    },
    sessionWrap: {
      width: "50%",
    },
    sessionWrapMdDown: {
      width: "100%",
    },
  })
);

export default observer(RegisterPage);
