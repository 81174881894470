import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { AppTheme } from "styles/theme";
import { useHistory } from "react-router-dom";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import useDepsContainer from "hooks/useDepsContainer";
import Flex from "components/common/Flex";
import Button from "@material-ui/core/Button";
import { AlllogoSvg, LogoTextSvg } from "assets/icons";
import LangSelect from "components/LangSelect";
import UserIntegral from "./UserIntegral";
import UserAvatar from "./UserAvatar";

function MallHeader({ userInfo }) {
  const styles = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const { uiStore } = useDepsContainer();

  function toHome() {
    history.push("/");
  }

  function toLogin() {
    history.push(`/login`);
  }

  return (
    <Flex
      px={mdDown ? 2 : 0}
      width={mdDown ? "100%" : 1200}
      mt={4}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      {mdDown ? (
        <AlllogoSvg
          height={40}
          style={{ cursor: "pointer" }}
          onClick={toHome}
        />
      ) : (
        <LogoTextSvg
          width={118}
          style={{ cursor: "pointer" }}
          onClick={toHome}
        />
      )}
      <Flex>
        {!mdDown && (
          <LangSelect
            SelectSx={{
              borderRadius: "24px",
              height: "36px",
              fontSize: "18px",
              fontWeight: "400",
              border: "2px solid #232429",
              "& .MuiSvgIcon-root": {
                color: "black",
              },
            }}
            MenuItemSx={{}}
          />
        )}
        {!uiStore.isLogin && (
          <Flex ml={mdDown ? 0 : 4}>
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: mdDown ? "0 16px" : "0 37px",
                height: "36px",
                borderRadius: "80px",
                background: "#3B3C45",
              }}
              onClick={toLogin}
            >
              LOGIN
            </Button>
          </Flex>
        )}

        {uiStore.isLogin && (
          <>
            <Flex ml={mdDown ? 0 : 4}>
              <UserIntegral userInfo={userInfo} />
            </Flex>

            <Flex ml={mdDown ? 4 : 4} mr={mdDown ? 2 : 0}>
              <UserAvatar userInfo={userInfo} />
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) => createStyles({}));

export default observer(MallHeader);
