import { observer } from "mobx-react";
import { AppTheme, Colors } from "styles/theme";
import React, { useState, useMemo, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Flex from "components/common/Flex";
import Text from "components/common/Text";
import useDepsContainer from "hooks/useDepsContainer";
import AgeCalculator from "./AgeCalculator";
import {AssociateSvg, GradeSvg, VipSvg} from "assets/icons/index";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

interface InfoLabelProps {
    userInfo: any
    userConnectStatus: any
    userConnectTimes: any
    timesPropsStyle?: any
    timesPropsColor?: any
}

function InfoLabel({ userInfo, userConnectStatus, userConnectTimes, timesPropsStyle, timesPropsColor }: InfoLabelProps) {
  const theme = useTheme();
  const styles = useStyles();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();

  const { uiStore } = useDepsContainer();
  const { t } = useTranslation();
  const cachedT = useMemo(() => t, [t, uiStore.language]);

  const [moreAboutMe, setMoreAboutMe] = useState<string[]>([]);

  useEffect(() => {

  }, []);

  return (
      <Flex mt={1} centerY>
          {[0, 2].includes(userInfo?.connectStatus) && (
              <>
                  <Flex
                      className={styles.round}
                      mr={3}
                      bgcolor={"#17CF60"}
                  ></Flex>
                  <Flex className={styles.label}>{userConnectStatus}</Flex>
              </>
          )}

          <Flex mr={1}>
              <Flex>
                  <LocationOnIcon fontSize={"small"} />
              </Flex>
              {userInfo?.location?.address && (
                  <Flex className={styles.label}>
                      {userInfo.location.address}
                  </Flex>
              )}
          </Flex>

          {userConnectTimes ? (
              <Flex
                  bgcolor={timesPropsStyle ? "" : "#F3F4FC"}
                  style={timesPropsStyle ? timesPropsStyle : {
                      borderRadius: "100px",
                      color: "white",
                      padding: "0 8px",
                  }}
                  center
              >
                  <Flex mr={1} color={timesPropsColor ? timesPropsColor : "black"}>
                      <AssociateSvg width={19} height={12} />
                  </Flex>
                  <Text color={timesPropsColor ? timesPropsColor : "black"} className={styles.label}>
                      {userConnectTimes}
                  </Text>
              </Flex>
          ) : null}
      </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
      round: {
          width: "8px",
          height: "8px",
          borderRadius: "50%",
      },
      label: {
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "20px",
      },
  })
);

export default observer(InfoLabel);
