import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { AppTheme } from "styles/theme";
import { useSetState } from "ahooks";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import Flex from "components/common/Flex";
import MallCard from "./components/MallCard";
import TextCard from "./components/TextCard";
import MallHeader from "./components/MallHeader";
import PageFooter from "components/Footer";
import { pointsProductList, usernameList } from "api/malls";
import useDepsContainer from "hooks/useDepsContainer";

interface MallPageProps {}

function MallPage(props: MallPageProps) {
  const styles = useStyles(props);
  const theme = useTheme();
  const history = useHistory();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const { uiStore } = useDepsContainer();

  const [tradingCards, setTradingCards] = useState<any>([]);
  const [userInfo, setUserInfo] = useState<any>({});

  useEffect(() => {
    const token = localStorage.getItem("token");
    uiStore.isLogin = !!token;

    usernameList({}).then((res) => {
      const data = res?.data;
      setUserInfo(data?.data || {});
    });

    pointsProductList({
      type: 1,
      channel: 1,
    }).then((res) => {
      const data = res?.data;

      if (data?.code !== 200) {
        return;
      }

      setTradingCards(data.data);
    });
  }, []);

  function toPayment(item) {
      // const jsonString = JSON.stringify(item)
      // const base64String = btoa(jsonString)
      // history.push(`/payment?param=${base64String}`)
  }

  return (
    <Flex className={styles.bg} column centerY>
      <MallHeader userInfo={userInfo} />

      <Flex
        mb={mdDown ? 6 : 12}
        mt={5}
        px={mdDown ? 4 : 0}
        width={mdDown ? "100vw" : 1200}
      >
        <Flex
          className={mdDown ? styles.moveTitle : styles.title}
          display={"inline-block"}
        >
          <Flex component={"b"} display={"inline-block"} color={"black"}>
            Pumpkin Store.
          </Flex>{" "}
          The fast track to your chosen one’s heart.
        </Flex>
      </Flex>

      <Flex px={mdDown ? 4 : 0} width={mdDown ? "100vw" : 1200}>
        <Flex className={styles.mallContCard} justifyContent={"space-between"}>
          {tradingCards.map((item, index) => {
            return mdDown ? (
              <MallCard width={"49%"} item={item} key={`cards-${index}`} toPayment={() => toPayment(item)} />
            ) : (
              <MallCard
                width={"calc(33.33% - 10px)"}
                item={item}
                key={`cards-${index}`}
                toPayment={() => toPayment(item)}
              />
            );
          })}
        </Flex>
      </Flex>

      <Flex
        mb={6}
        mt={mdDown ? 1 : 5}
        px={mdDown ? 4 : 0}
        width={mdDown ? "100vw" : 1200}
      >
        <Flex
          className={mdDown ? styles.moveTitle : styles.title}
          style={{
            display: "inline-block",
            fontSize: "18px",
          }}
        >
          <Flex component={"b"} display={"inline-block"} color={"black"}>
            Easy Access to the Store Happiness.
          </Flex>{" "}
          Nothing can get in your way to Happiness.
        </Flex>
      </Flex>

      <Flex mb={14} px={mdDown ? 4 : 0} width={mdDown ? "100vw" : 1200}>
        <TextCard />
      </Flex>

      <PageFooter />
    </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    bg: {
      width: "100vw",
      minHeight: "100vh",
      color: "black",
      overflowX: "hidden",
      backgroundColor: "#FBFBFB",
    },
    title: {
      fontWeight: 700,
      fontSize: "48px",
      lineHeight: "56px",
      color: "#6E6E73",
      width: "70%",
    },
    moveTitle: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
      color: "#6E6E73",
      width: "100%",
    },
    smallCardCont: {
      display: "flex",
      justifyContent: "space-between",
    },
    smallCard: {
      padding: "30px",
      boxShadow: "2px 4px 12px rgba(0, 0, 0, 0.08)",
    },
    smallCardText: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
      color: "balck",
    },
    mallContCard: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
  })
);

export default observer(MallPage);
