import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { AppTheme } from "styles/theme";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { PumpskinSvg } from "assets/icons";
import Flex from "components/common/Flex";

function UserIntegral({ userInfo }) {
  const styles = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Flex
      px={3}
      className={styles.integral}
      style={{
        border: mdDown ? "1px solid #F4907B" : "1px solid #18181B",
      }}
    >
      <PumpskinSvg width={30} height={30} />
      <Flex ml={1} className={styles.text}>
        {userInfo?.pumpkin || 0}
      </Flex>
    </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    integral: {
      alignItems: "center",
      borderRadius: "40px",
    },
    text: {
      fontWeight: 700,
      fontSize: "18px",
      letterSpacing: "-0.2px",
      color: "black",
    },
  })
);

export default observer(UserIntegral);
