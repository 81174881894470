import { observer } from 'mobx-react';
import React, { useState, useMemo } from 'react';
import Flex from 'components/common/Flex';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-creative';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/swiper-bundle.css';
import './swiperInvite.css';

import { EffectCreative, Pagination, Navigation } from 'swiper';
import { AppTheme, Colors } from 'styles/theme';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import HttpsIcon from '@material-ui/icons/Https';
import { ArrowLeftCircleSvg, ArrowRightCircleSvg, PumpkinCoinsSvg } from 'assets/icons';
import useDepsContainer from 'hooks/useDepsContainer';
import { useTranslation } from 'react-i18next';

interface SwiperInviteProps {
  photos: any[];
  toRegister: any;
}

function SwiperInvite(props: SwiperInviteProps) {
  const theme = useTheme();
  const styles = useStyles(props);
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { photos, toRegister } = props;
  const { uiStore } = useDepsContainer();
  const { t, i18n } = useTranslation();
  const cachedT = useMemo(() => t, [t, uiStore.language]);

  const MdImg = ({ style, item }) => {
    const isPrivacy = item?.type === 101; // 101=隐私照片
    const isTopay = item?.amount > 0; // >0 付费照片
    return (
      <Flex className={styles.moveSlide}>
        {isPrivacy ? (
          <Flex className={styles.permissions} column center>
            <Flex>
              <HttpsIcon fontSize={'large'} />
            </Flex>
            <Flex my={3} width={'70%'} textAlign={'center'}>
              {cachedT('privacyTips')}
            </Flex>
            {item?.amount ? (
              <Flex
                bgcolor={'black'}
                px={6}
                style={{
                  alignItems: 'center',
                  borderRadius: '10px'
                }}
                onClick={imgClick}
              >
                <PumpkinCoinsSvg width={'18px'} /> <Flex ml={1}>{item?.amount}</Flex>
              </Flex>
            ) : null}
          </Flex>
        ) : isTopay ? (
          <Flex className={styles.permissions} column center>
            <Flex>
              <HttpsIcon fontSize={'large'} />
            </Flex>
            <Flex
              bgcolor={'black'}
              px={6}
              style={{
                alignItems: 'center',
                borderRadius: '10px'
              }}
              onClick={imgClick}
            >
              <PumpkinCoinsSvg width={'18px'} /> <Flex ml={1}>{item?.amount || 0}</Flex>
            </Flex>
          </Flex>
        ) : null}
        {isPrivacy || isTopay ? <Flex className={styles.encryption}></Flex> : null}
        <Flex
          className={styles.slideMask}
          style={{
            background: mdDown
              ? 'linear-gradient(180deg, rgba(0, 0, 0, 0) 21.98%, rgba(0, 0, 0, 0.470677) 66.27%, #000000 96.49%)'
              : 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 39.53%, rgba(0, 0, 0, 0.47) 75.47%, #000 100%)'
          }}
        ></Flex>
        <Flex className={styles.slide} style={style}></Flex>
      </Flex>
    );
  };

  const handleParentClick = event => {
    event.stopPropagation();
  };

  const handlePrevClick = event => {
    event.stopPropagation();
  };

  const imgClick = event => {
    event.stopPropagation();
    toRegister();
  };

  return (
    <Flex width={'100%'} height={'100%'}>
      <Swiper
        grabCursor={true}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next'
        }}
        effect={'creative'}
        spaceBetween={30}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: function (index, className) {
            const type = photos[index]?.type || 1; // 101=隐私照片
            const amount = photos[index]?.amount || 0; // >0 付费照片
            if (type === 101) {
              return `<span class="${className} swiper-pagination-dating-item"><img src="/images/lock-closed.png" style="width:100%" /></span>`;
            } else {
              return `<span class="${className} swiper-pagination-dating-item"></span>`;
            }
          }
        }}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: ['-20%', 0, -1]
          },
          next: {
            translate: ['100%', 0, 0]
          }
        }}
        modules={[EffectCreative, Pagination, Navigation]}
        className="mySwiper3"
      >
        {photos.map((item, index) => {
          return (
            <SwiperSlide key={item + index}>
              <MdImg
                item={item}
                style={{
                  backgroundImage: `url(${item.path})`
                }}
              />
            </SwiperSlide>
          );
        })}

        <>
          <Flex className="swiper-button-prev" style={mdDown ? {} : { top: '80%' }} onClick={handlePrevClick}>
            <ArrowLeftCircleSvg width={40} height={40} />
          </Flex>
          <Flex className="swiper-button-next" style={mdDown ? {} : { top: '80%' }} onClick={handleParentClick}>
            <ArrowRightCircleSvg width={40} height={40} />
          </Flex>
        </>

        <div className="swiper-pagination" style={mdDown ? { top: '1rem', bottom: 'auto' } : {}}></div>
      </Swiper>
    </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    slide: {
      width: '100%',
      height: '100%',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      position: 'relative',
      zIndex: 2
    },
    moveSlide: {
      width: '100%',
      height: '100%',
      position: 'relative'
    },
    slideMask: {
      content: ' ',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 3
    },
    encryption: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backdropFilter: 'blur(30px)',
      zIndex: 4
    },
    permissions: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 5,
      fontSize: '14px'
    }
  })
);

export default observer(SwiperInvite);
