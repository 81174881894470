import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { AppTheme } from "styles/theme";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Paper } from "@material-ui/core";
import {
  CreditCard1Svg,
  CreditCardSvg,
  LockClosedSvg,
  MoodHappySvg,
} from "assets/icons";
import Flex from "components/common/Flex";

interface TextCardProps {}

function TextCard(props: TextCardProps) {
  const styles = useStyles(props);
  const theme = useTheme();
  const history = useHistory();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  function CardItem() {
    return (
      <>
        <Flex
          className={mdDown ? styles.moveSmallCard : styles.smallCard}
          borderRadius={"18px"}
          mr={5}
          column
        >
          <CreditCardSvg />
          <Flex
            mt={mdDown ? 3 : 7}
            className={mdDown ? styles.moveSmallCardText : styles.smallCardText}
            display={"inline-block"}
          >
            Flexible payment.{" "}
            <Flex component={"b"} display={"inline-block"} color={"#E85130"}>
              Pay with any payment method, fiat or crypto
            </Flex>
          </Flex>
        </Flex>
        <Flex
          className={mdDown ? styles.moveSmallCard : styles.smallCard}
          borderRadius={"18px"}
          mr={5}
          column
        >
          <CreditCard1Svg />
          <Flex
            mt={mdDown ? 3 : 7}
            className={mdDown ? styles.moveSmallCardText : styles.smallCardText}
            display={"inline-block"}
          >
            Flexible payment.{" "}
            <Flex component={"b"} display={"inline-block"} color={"#E85130"}>
              Pay with any payment method, fiat or crypto
            </Flex>
          </Flex>
        </Flex>
        <Flex
          className={mdDown ? styles.moveSmallCard : styles.smallCard}
          borderRadius={"18px"}
          mr={5}
          column
        >
          <LockClosedSvg />
          <Flex
            mt={mdDown ? 3 : 7}
            className={mdDown ? styles.moveSmallCardText : styles.smallCardText}
            display={"inline-block"}
          >
            Flexible payment.{" "}
            <Flex component={"b"} display={"inline-block"} color={"#E85130"}>
              Pay with any payment method, fiat or crypto
            </Flex>
          </Flex>
        </Flex>
        <Flex
          className={mdDown ? styles.moveSmallCard : styles.smallCard}
          borderRadius={"18px"}
          column
        >
          <MoodHappySvg />
          <Flex
            mt={mdDown ? 3 : 7}
            className={mdDown ? styles.moveSmallCardText : styles.smallCardText}
            display={"inline-block"}
          >
            Flexible payment.{" "}
            <Flex component={"b"} display={"inline-block"} color={"#E85130"}>
              Pay with any payment method, fiat or crypto
            </Flex>
          </Flex>
        </Flex>
      </>
    );
  }

  return mdDown ? (
    <Paper
      className={styles.scrollContainer}
      sx={{
        boxShadow: "none",
        paddingBottom: "5px",
      }}
    >
      <CardItem />
    </Paper>
  ) : (
    <Flex className={styles.smallCardCont}>
      <CardItem />
    </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    smallCardCont: {
      display: "flex",
      justifyContent: "space-between",
    },
    scrollContainer: {
      width: "100%",
      display: "flex",
      overflowX: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      scrollbarWidth: "none",
    },
    smallCard: {
      padding: "30px",
      boxShadow: "2px 4px 12px rgba(0, 0, 0, 0.08)",
    },
    moveSmallCard: {
      padding: "30px",
        flexBasis: "300px",
        flexGrow: 0,
        flexShrink: 0,
      boxShadow: "2px 4px 12px rgba(0, 0, 0, 0.08)",
    },
    smallCardText: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
      color: "balck",
    },
    moveSmallCardText: {
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "24px",
      color: "balck",
    },
  })
);

export default observer(TextCard);
