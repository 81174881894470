import { useMediaQuery, useTheme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { LogoTextSvg } from 'assets/icons';
import PageFooter from 'components/Footer';
import Header from 'components/Header';
import Flex from 'components/common/Flex';
import Text from 'components/common/Text';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { AppTheme } from 'styles/theme';

interface UserDataPageProps {}

const UserDataPage = (props: UserDataPageProps) => {
  const styles = useStyles(props);
  const history = useHistory();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Flex
      width="100vw"
      height={'100vh'}
      className={styles.pageContainer}
      bgcolor={'rgba(255, 255, 255)'}
      column
      centerY
    >
      <Header bgcolor={'rgba(255, 255, 255)'} />

      <Flex width={'100%'} flex={1} column mb={mdDown ? 0 : 15} py={4} px={mdDown ? 6 : 2} maxWidth={1200}>
        <Flex width={'100%'} justifyContent={'flex-end'}>
          <LogoTextSvg style={{ width: 120 }} />
        </Flex>

        <Text
          style={{
            fontWeight: 700,
            fontSize: 48
          }}
          color={`#E85130`}
        >
          User Data Deletion Instructions
        </Text>

        <Text
          component={'span'}
          color="#18181B"
          whiteSpace={'break-spaces'}
          mt={2}
          style={{ textAlign: 'justify' }}
          className={styles.aStyle}
        >
          {/* <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            Introduction
          </Text> */}
          {`
To delete your user data, please follow the steps below:
`}
          {`
1. Log in to your account on the Pumpkin app.
2. Go to the "Settings" section, usually found under the "Me" tab.
3. Look for an option that says "Delete account".
4. Click on the "Delete account" option and follow any additional prompts or instructions that may appear.
5. You may be asked to verify your password or provide a reason for deleting your account.
6. Confirm your decision to delete your account and agree to any terms or conditions if prompted.
7. Once the deletion process is complete, all your user data will be permanently removed from our systems.
`}

          {`
Please note that deleting your account is irreversible, and you will lose access to all associated data, including saved settings, preferences, and any content you have uploaded or created. Make sure to back up any important information before proceeding with the deletion.
`}

          {`
If you encounter any issues or need further assistance, please contact our support team for help.

`}
        </Text>
      </Flex>

      <Flex className={styles.footerWrap} mt={mdDown ? 8 : 1.6}>
        <PageFooter isHome />
      </Flex>
    </Flex>
  );
};

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    pageContainer: {
      overflowX: 'hidden',
      overflowY: 'auto'
    },
    sessionWrap: {
      width: '100%'
    },
    footerWrap: {
      width: '100%'
    },
    aStyle: {
      '& a': {
        color: '#E85130'
      }
    }
  })
);

export default observer(UserDataPage);
