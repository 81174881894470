import { createStyles, makeStyles } from "@material-ui/styles";
import Flex from "components/common/Flex";
import { observer } from "mobx-react";
import { AppTheme } from "styles/theme";

const StepDots = (props: any) => {
    const styles = useStyles({
        activeStep: props.activeStep || 1,
        direction: props.direction || "",
        ...props,
    });

    return (
        <Flex
            position={props.absolute ? "absolute" : "static"}
            bottom={0}
            left={5}
            className={styles.dotsWrapper}
            centerY
            width={"100%"}
            centerX={props.center}
        >
            <Flex
                mr={1}
                className={props.activeStep === 1 ? "dot-active-1" : "dot"}
            ></Flex>
            <Flex
                mx={1}
                className={props.activeStep === 2 ? "dot-active-2" : "dot"}
            ></Flex>
            <Flex
                ml={1}
                className={props.activeStep === 3 ? "dot-active-3" : "dot"}
            ></Flex>
        </Flex>
    );
};

const useStyles = makeStyles((theme: AppTheme) =>
    createStyles({
        dotsWrapper: {
            "& .dot": {
                borderRadius: "50%",
                width: 8,
                height: 8,
                background: "#8C8E9C",
            },
            "& .dot-active-1": {
                background: "white",
                borderRadius: "50%",
                width: 8,
                height: 8,
                transition: `all 0.3s ease-in-out`,
                animation: (props: any) =>
                    props.activeStep === 1 && props.direction === ""
                        ? "$dot_moving_left 0.3s ease-in-out"
                        : "",
            },
            "& .dot-active-2": {
                background: "white",
                borderRadius: "50%",
                width: 8,
                height: 8,
                transition: `all 0.3s ease-in-out`,
                animation: (props: any) =>
                    props.activeStep === 2 && props.direction === ""
                        ? "$dot_moving_left 0.3s ease-in-out"
                        : "",
            },
            "& .dot-active-3": {
                background: "white",
                borderRadius: "50%",
                width: 8,
                height: 8,
                animation: "$dot_moving_right 0.3s ease-in-out",
            },
        },
        "@keyframes dot_moving_right": {
            "0%": {
                opacity: 0,
                transform: "translateX(-20px)",
            },
            "100%": {},
        },
        "@keyframes dot_moving_left": {
            "0%": {
                opacity: 0,
                transform: "translateX(20px)",
            },
            "100%": {},
        },
    })
);

export default observer(StepDots);
