import { observer } from 'mobx-react';
import React, { useState, useMemo, useEffect } from 'react';
import { AppTheme, Colors } from 'styles/theme';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Flex from 'components/common/Flex';
import IconButton from '@material-ui/core/IconButton';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import { useHistory, useLocation } from 'react-router-dom';
import { GiftSvg, StarsSvg, XmarkSvg } from 'assets/icons';
import { isBrowser, isMobile } from 'react-device-detect';

interface InvitePageProps {
  downTitle?: string;
  kolUrl?: string;
  userConciseUserId?: string;
}

function InterButton(props: InvitePageProps) {
  const theme = useTheme();
  const styles = useStyles(props);
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();

  const location = useLocation();
  const usernameInviter = location.pathname.split('/')?.[1] || null;

  const [username, setUsername] = useState(usernameInviter);

  useEffect(() => {
    if (usernameInviter) {
      setUsername(usernameInviter);
    }
  }, [usernameInviter]);

  // function toRegister(event) {
  //   event.preventDefault();
  //   if (isMobile) {
  //     let query = `?title=${props?.downTitle}`;
  //     const url = props.kolUrl;
  //     if (url) {
  //       query += `&redirectUrl=${url.replace(/https?:\/\//, '')}`;
  //     }
  //     window.location.href = `${process.env.REACT_APP_DOWN_URL}${username}${query}`;
  //     return;
  //   }
  //   //history.push(`/register?ref=${username}`);
  //   if (props.kolUrl) {
  //     window.open(props.kolUrl);
  //   } else {
  //     window.open('https://app.adjust.com/176ugajm');
  //   }
  // }

  function toRegister(event) {
    event.preventDefault();
    history.push(`/register?ref=${username}&title=${props?.downTitle}&conciseUserId=${props?.userConciseUserId}`)
  }

  return (
    <Flex
      style={
        mdDown
          ? {
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between'
            }
          : {}
      }
    >
      <Flex mr={mdDown ? 0 : 8}>
        <IconButton
          style={{
            color: '#D1B3FF',
            background: 'black',
            border: '1px solid #E5AD1A'
          }}
          onClick={toRegister}
        >
          <GiftSvg />
        </IconButton>
      </Flex>
      <Flex mr={mdDown ? 0 : 8}>
        <IconButton
          style={{
            color: '#0BA3F8',
            background: 'black',
            borderRadius: '80px',
            paddingLeft: '30px',
            paddingRight: '30px',
            border: '1px solid #0BA3F8'
          }}
          onClick={toRegister}
        >
          <XmarkSvg />
        </IconButton>
      </Flex>
      <Flex mr={mdDown ? 0 : 8}>
        <IconButton
          style={{
            color: '#F2755A',
            background: 'black',
            borderRadius: '80px',
            paddingLeft: '30px',
            paddingRight: '30px',
            border: '1px solid #F2755A'
          }}
          onClick={toRegister}
        >
          <FavoriteOutlinedIcon />
        </IconButton>
      </Flex>
      <Flex>
        <IconButton
          style={{
            color: '#B8F9EA',
            background: 'black',
            border: '1px solid #B8F9EA'
          }}
          onClick={toRegister}
        >
          <StarsSvg />
        </IconButton>
      </Flex>
    </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) => createStyles({}));

export default observer(InterButton);
