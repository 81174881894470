import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./lang/en.json";
import vn from "./lang/vn.json";
import cs from "./lang/cs.json";
import ct from "./lang/ct.json";
import IN from "./lang/in.json";
import kr from "./lang/kr.json";
import jp from "./lang/jp.json";
import sp from "./lang/sp.json";

export const LangKeys = {
  en: "en",
  vn: "vn",
  cs: "cs",
  ct: "ct",
  in: "in",
  kr: "kr",
  jp: "jp",
  sp: "sp",
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: LangKeys.en,
    resources: {
      [LangKeys.en]: {
        translation: en,
      },
      [LangKeys.vn]: {
        translation: vn,
      },
      [LangKeys.cs]: {
        translation: cs,
      },
      [LangKeys.ct]: {
        translation: ct,
      },
      [LangKeys.in]: {
        translation: IN,
      },
      [LangKeys.kr]: {
        translation: kr,
      },
      [LangKeys.jp]: {
        translation: jp,
      },
      [LangKeys.sp]: {
        translation: sp,
      },
    },
    debug: false,
    interpolation: { escapeValue: false },
  });

export default i18n;
