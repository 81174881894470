import {LangKeys} from "locales/i18n";

export default {
   [LangKeys.en]: {
       title: "Register | Pumpkin Meet",
       description: "Pumpkin is a Secret Dating Super App: Date, Chat, Matchmake, Send Gift in One App, with Absolute Secrecy and Security. Pumpkin includes standard features of a Dating app, an End-to-End Encrypted Messenger, a non-custodial Crypto Wallet and more!",
   },
   [LangKeys.vn]: {
       title: "đăng ký | Pumpkin Meet",
       description: "Pumpkin là một ứng dụng hẹn hò bí mật: Hẹn hò, Trò chuyện, Kết nối, Tặng quà trên cùng một ứng dụng. Bảo mật và riêng tư tuyệt đối. Ứng dụng Pumpkin bao gồm đủ các tính năng của một ứng dụng hẹn hò, ứng dụng chat mã hóa, ví tiền điện từ phi tập trung, và hơn thế nứa.",
   },
   [LangKeys.cs]: {
       title: "注册 | Pumpkin Meet",
       description: "Pumpkin 是一款秘密约会超级应用程序：约会、聊天、媒人、送礼物在一个应用程序中，具有绝对的保密性和安全性。Pumpkin 包括约会应用程序、端到端加密信使、非托管加密钱包等的标准功能！",
   },
   [LangKeys.ct]: {
       title: "Register | Pumpkin Meet",
       description: "Pumpkin 是一款秘密約會超級應用程序：約會、聊天、媒人、送禮物在一個應用程序中，具有絕對的保密性和安全性。Pumpkin 包括約會應用程序、端到端加密信使、非託管加密錢包等的標準功能！",
   },
   [LangKeys.in]: {
       title: "Pumpkin Meet - Kencan Rahasia & Chat",
       description: "Pumpkin adalah app super Kencan Rahasia: Kencan, Chat, Perjodohan, Kirim hadiah di dalam satu app dengan keamanan dan kerahasiaan yang terjamin. Pumpkin menyediakan fitur standar dari sebuah app kencan, Messenger terenkripsi End-to-End, kripto wallet yang non-kustodial dan masih banyak lainnya!",
   },
   [LangKeys.kr]: {
       title: " 등록부 | Pumpkin Meet",
       description: "펌킨은 비밀 데이팅 앱입니다: 철저하게 비밀과 보안이 보장된 환경에서 데이트, 채팅, 매칭, 앱 내의 선물보내기가 가능합니다. 펌킨은 표준의 데이팅 앱이면서, 동시에 E2EE 암호화 메신저, 탈중앙화지갑을 포함하고 있습니다. 우리의 목표는 당신이 신뢰할 수 있는 사교활동을 통해 가치 있는 사람들과의 모든 관계 가능성을 탐구할 수 있도록 돕는것입니다. 펌킨의 3가지 핵심 원칙은 다음과 같습니다.",
   },
   [LangKeys.jp]: {
       title: "登録 | Pumpkin Meet",
       description: "パンプキンは秘密の出会い系スーパーアプリ：デート、チャット、マッチング、ギフト贈呈を1つのアプリで極秘&安全性で実現します。 パンプキンは、出会い系アプリの標準機能、エンドツーエンド暗号化メッセンジャー、ノンカストディアル暗号資産ウォレットなどが含まれています。",
   },
   [LangKeys.sp]: {
       title: "Registro | Pumpkin Meet",
       description: "Pumpkin es una Super App de Citas Secretas: Cita, Chat, Matchmake, Envia Regalos en una App, con Absoluto Secreto y Seguridad. Pumpkin incluye características estándar de una aplicación de Citas, un Mensajero Encriptado de Extremo a Extremo, una Cartera Criptográfica no custodiada y ¡mucho más!",
   }
}