import {LangKeys} from "locales/i18n";


export default {
   [LangKeys.en]: {
       title: "Pumpkin - Social to Earn",
       description: "Meet, Chat, Matchmake, Send Gift in One App, with Absolute Secrecy and Security. 3-in-1: a Social network, a Private Messenger, and a Crypto Wallet.",
   },
   [LangKeys.vn]: {
       title: "Pumpkin - Trò chuyện, Gặp gỡ và Kiếm tiền",
       description: "Gặp gỡ, Trò chuyện, Kết nối, Tặng quà trên cùng một ứng dụng. Bảo mật và riêng tư tuyệt đối. 3 trong 1: Mạng xã hội + Nhắn tin + Ví điện tử.",
   },
   [LangKeys.cs]: {
       title: "Pumpkin 南瓜 - 社交即赚",
       description: "在一个应用程序中约会、聊天、配对、发送礼物，绝对保密和安全。三合一：约会应用程序、私人信使和加密钱包。",
   },
   [LangKeys.ct]: {
       title: "Pumpkin 南瓜 - 社交就赚",
       description: "在一個應用程序中約會、聊天、配對、發送禮物，絕對保密和安全。三合一：約會應用程序、私人信使和加密錢包。",
   },
   [LangKeys.in]: {
       title: "Pumpkin - Social to Earn",
       description: "Kencan, Chat, Perjodohan, Kirim Hadiah di satu app dengan keamanan dan kerahasiaan yang terjamin. 3-in-1: App Dating, Private Messenger, dan Wallet Kripto.",
   },
   [LangKeys.kr]: {
       title: "Pumpkin 펌킨-소셜 투 언, SNS로 돈 벌자",
       description: "철저하게 비밀과 보안이 보장된 환경에서 데이트, 채팅, 매칭, 앱 내의 선물보내기가 가능합니다. 3-in-1: 데이팅 앱, 비밀 메신저, 암호화지갑 이 세가지가 한 앱안에 들어있습니다.",
   },
   [LangKeys.jp]: {
       title: "Pumpkin パンプキン - ソーシャルトゥアーン",
       description: "デート、チャット、マッチング、ギフト贈呈を1つのアプリで極秘&安全性で実現します。3-in-1：出会い系アプリ、プライベートメッセンジャー、暗号資産ウォレット",
   },
   [LangKeys.sp]: {
       title: "Pumpkin - Social para Ganar",
       description: "Cita, Chatea, Haz Pareja, Envía Regalos en Una Aplicación, con Absoluto Secreto y Seguridad. 3-en-1: una aplicación de citas, un mensajero privado, y un Crypto Wallet.",
   }
}