import React, { useState, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { AppTheme } from "styles/theme";
import { MenuItem, Select, useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import Flex from "components/common/Flex";
import Text from "components/common/Text";
import i18n, { LangKeys } from "locales/i18n";
import {
  ChinaFlag,
  EnglandFlagIcon,
  IndonesiaFlag,
  JapanFlag,
  KoreanFlag,
  SpainFlag,
  VietnamFlag,
} from "assets/icons";
import ExpandMore from "@material-ui/icons/ExpandMore";
import useDepsContainer from "hooks/useDepsContainer";

interface LangSelectProps {
  SelectSx?: object;
  MenuItemSx?: object;
}

const languageOptions = [
  {
    key: LangKeys.en,
    name: "English",
    icon: <EnglandFlagIcon width={32} height={18} />,
  },
  {
    key: LangKeys.vn,
    name: "Tiếng Việt",
    icon: <VietnamFlag width={32} height={18} />,
  },
  {
    key: LangKeys.cs,
    name: "简体中文",
    icon: <ChinaFlag width={32} height={18} />,
  },
  {
    key: LangKeys.ct,
    name: "繁體中文",
    icon: <ChinaFlag width={32} height={18} />,
  },
  {
    key: LangKeys.in,
    name: "Bahasa",
    icon: <IndonesiaFlag width={32} height={18} />,
  },
  {
    key: LangKeys.kr,
    name: "한국어",
    icon: <KoreanFlag width={32} height={18} />,
  },
  {
    key: LangKeys.jp,
    name: "日本語",
    icon: <JapanFlag width={32} height={18} />,
  },
  {
    key: LangKeys.sp,
    name: "Español",
    icon: <SpainFlag width={32} height={18} />,
  },
];

function LangSelect({ SelectSx, MenuItemSx }: LangSelectProps) {
  const styles = useStyles();
  const { uiStore } = useDepsContainer();

  useEffect(() => {
    const langCached = localStorage.getItem("@lang");
    i18n.changeLanguage(langCached || LangKeys.en);
    uiStore.language = langCached || LangKeys.en;
  }, []);

  const changeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
    uiStore.language = e.target.value;

    localStorage.setItem("@lang", e.target.value);
  };

  return (
    <>
      <Select
        value={uiStore.language}
        variant="standard"
        defaultValue={LangKeys.en}
        disableUnderline
        sx={Object.assign(
          {},
          SelectSx
        )}
        className={styles.select}
        onChange={changeLanguage}
        IconComponent={ExpandMore}
        SelectDisplayProps={{
          style: {
            maxWidth: 100,
          },
        }}
        inputProps={{
          style: {
            maxWidth: 80,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
      >
        {languageOptions.map((item, index) => (
          <MenuItem value={item.key} key={item.key}>
            <Flex centerY key={item.key}>
              {/* {item.icon}&nbsp;&nbsp; */}
              <Text
                ml={1}
                style={
                  Object.assign(
                    {},
                    {
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                    MenuItemSx
                  ) as any
                }
                className={styles.menuText}
              >
                {item.name}
              </Text>
            </Flex>
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    menuText: {
      fontFamily: "'Rubik' !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      fontSize: "18px !important",
      lineHeight: "155% !important",
      letterSpacing: "-0.03em !important",
      textTransform: "none",
    },
    select: {
      "&:after": {
        borderBottomColor: "white",
      },
      "& .MuiSvgIcon-root": {
        color: "white",
      },
    },
  })
);

export default observer(LangSelect);
