import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { AppTheme } from "styles/theme";
import { useHistory } from "react-router-dom";
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import useDepsContainer from "hooks/useDepsContainer";
import Flex from "components/common/Flex";
import Radio from "@material-ui/core/Radio";
import {
  BitcoinSvg,
  DiscoverSvg,
  MaestroSvg,
  MastercardSvg,
  PaypalLightSvg,
  VisaLightSvg,
} from "assets/icons";

export function PayPalTitle() {
  const styles = useStyles();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [buyIcon, setBuyIcon] = useState<boolean>(false);

  function handleChange() {
    setBuyIcon(!buyIcon);
  }

  return (
    <Flex
      width={"100%"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"flex-start"}
      column={mdDown}
    >
      <Flex centerY>
        <Radio
          checked={buyIcon}
          onChange={handleChange}
          value="buyIcon"
          color="default"
          className={styles.root}
          checkedIcon={
            <span className={clsx(styles.icon, styles.checkedIcon)} />
          }
          icon={<span className={styles.icon} />}
          size="small"
        />
        PayPal
      </Flex>
      <Flex px={mdDown ? 0 : 7} my={mdDown ? 1 : 0}>
        You will be redirected to the PayPal website after submitting your order
      </Flex>
      <Flex  my={mdDown ? 1 : 0}>
        <Flex>
          <PaypalLightSvg />
        </Flex>
      </Flex>
    </Flex>
  );
}

function PayPal() {
  const styles = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const { uiStore } = useDepsContainer();

  useEffect(() => {}, []);

  return (
    <Flex
      className={styles.piece}
      style={{ opacity: 0.8 }}
      width={"100%"}
      px={3}
      py={3}
      pb={10}
      column
    >
      <Flex className={styles.bark}></Flex>
      <PayPalTitle />
      <Flex className={styles.pos} color={"#1765CF"}>
        Coming soon
      </Flex>
    </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    h1: {},
    flex: {
      display: "flex",
      justifyContent: "space-between",
    },
    piece: {
      border: "1px solid #B2BCCA",
      borderRadius: "4px",
      background: "white",
      position: "relative",
    },
    bnt: {
      border: "1px solid #AAACBB",
      "&.MuiButton-root": {},
    },
    pos: {
      position: "absolute",
      bottom: "12px",
      left: "12px",
      zIndex: 2,
    },
    bark: {
      position: "absolute",
      width: "100%",
      height: "100%",
      background: "white",
      opacity: "0.6",
      top: 0,
      left: 0,
      zIndex: 1,
    },

    root: {
      padding: "9px 0 !important",
      paddingRight: "9px !important",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      borderRadius: "50%",
      width: 16,
      height: 16,
      boxShadow:
        "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      backgroundColor: "#f5f8fa",
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
      },
      "input:hover ~ &": {
        backgroundColor: "#ebf1f5",
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(206,217,224,.5)",
      },
    },
    checkedIcon: {
      backgroundColor: "#137cbd",
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 16,
        height: 16,
        backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: "#106ba3",
      },
    },
  })
);

export default observer(PayPal);
