import { observer } from "mobx-react";
import { AppTheme, Colors } from "styles/theme";
import React, { useState, useMemo, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Flex from "components/common/Flex";
import useDepsContainer from "hooks/useDepsContainer";

function InfoAbout({ userAbout }) {
  const theme = useTheme();
  const styles = useStyles();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();

  const { uiStore } = useDepsContainer();
  const { t } = useTranslation();
  const cachedT = useMemo(() => t, [t, uiStore.language]);

  return (
      <Flex className={styles.label} color={"#232429"} column>
          <Flex className={styles.h3} component={"h3"}>
              {cachedT("aboutMe")}
          </Flex>
          <Flex mt={2}>{userAbout}</Flex>
      </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
      label: {
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "20px",
      },
      h3: {
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "20px",
          color: "#6B6D7B",
      },
  })
);

export default observer(InfoAbout);
