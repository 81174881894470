import { useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import Flex from "components/common/Flex";
import { observer } from "mobx-react";
import { AppTheme } from "styles/theme";
import Publicity from "../Auth/components/Publicity";
import LoginForm from "../Auth/components/LoginForm";

interface LoginPageProps {}

const LoginPage = (props: LoginPageProps) => {
  const styles = useStyles(props);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Flex
      width="100vw"
      height={"100vh"}
      style={{
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #5E1708 100%)",
      }}
    >
      <Flex width="100vw" height={"100vh"} className={styles.pageContainer} column={mdDown}>
        <Flex className={mdDown ? styles.sessionWrapMdDown : styles.sessionWrap}>
          <Publicity />
        </Flex>
        <Flex className={mdDown ? styles.sessionWrapMdDown : styles.sessionWrap} center>
            <LoginForm />
        </Flex>
      </Flex>
    </Flex>
  );
};

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    pageContainer: {
      overflowX: "hidden",
      overflowY: "auto",
      backgroundImage: "url(/images/login-back.png)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom",
      backgroundSize: "100% 100%",
    },
    sessionWrap: {
      width: "50%",
    },
    sessionWrapMdDown: {
      width: "100%",
    },
  })
);

export default observer(LoginPage);
