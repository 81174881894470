import { observer } from 'mobx-react';
import React, { useState, useEffect, useMemo } from 'react';
import { AppTheme } from 'styles/theme';
import { withStyles, createStyles, makeStyles } from '@material-ui/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Flex from 'components/common/Flex';
import LangSelect from 'components/LangSelect';
import { useHistory, useLocation } from 'react-router-dom';
import { LogoTextSvg, MoveLogoSvg } from 'assets/icons';
import { isBrowser, isMobile } from 'react-device-detect';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useDepsContainer from 'hooks/useDepsContainer';
import { useTranslation } from 'react-i18next';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function InterHead({ downTitle, kolUrl, conciseUserId }) {
  const theme = useTheme();
  const styles = useStyles();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const history = useHistory();

  const location = useLocation();
  const usernameInviter = location.pathname.split('/')?.[1] || null;
  const searchParams = new URLSearchParams(location.search);

  const [username, setUsername] = useState(usernameInviter);
  const [open, setOpen] = useState(false);
  const { uiStore } = useDepsContainer();
  const { t, i18n } = useTranslation();
  const cachedT = useMemo(() => t, [t, uiStore.language]);

  const HtmlTooltip = withStyles(theme => ({
    arrow: {
      color: '#F3F4FC !important',
      fontSize: 24,
      '&::before': {
        border: '1px solid #DDDFEE'
      }
    },
    tooltip: {
      backgroundColor: '#F3F4FC !important',
      color: '#18181B  !important',
      width: 276,
      padding: '16px',
      border: '1px solid #DDDFEE'
    }
  }))(Tooltip);

  useEffect(() => {
    if (usernameInviter) {
      setUsername(usernameInviter);
    }
  }, [usernameInviter, isMobile]);

  function toHome() {
    history.push('/');
  }

  // const isDevice = () => {
  //   const u = navigator.userAgent
  //   const v = navigator.vendor
  //   return {
  //     Chrome: u.indexOf('Android') > -1 && u.indexOf('Chrome') > -1,
  //     iPhone: (u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1 || u.indexOf('iPad') > -1) && u.indexOf('Safari') > -1 && u.indexOf('CriOS') == -1
  //   }
  // }

  function toRegister(event) {
    event.preventDefault();
    history.push(`/register?ref=${username}&title=${downTitle}&conciseUserId=${conciseUserId}`)
  }

  // function toDownload(event) {
  //   event.preventDefault();
  //   let query = `?title=${downTitle}`;
  //   if (kolUrl) {
  //     query += `&redirectUrl=${kolUrl.replace(/https?:\/\//, '')}`;
  //   }
  //   window.location.href = `${process.env.REACT_APP_DOWN_URL}${username}${query}`;
  // }

  return (
    <Flex
      py={mdDown ? 2 : 0}
      px={mdDown ? 4 : 0}
      mt={4}
      width={mdDown ? '100vw' : 1200}
      display="flex"
      justifyContent="space-between"
      centerY
    >
      {mdDown ? (
        <MoveLogoSvg style={{ cursor: 'pointer' }} onClick={toHome} />
      ) : (
        <LogoTextSvg width={118} style={{ cursor: 'pointer' }} onClick={toHome} />
      )}

      <Flex>
        {!mdDown && (
          <LangSelect
            SelectSx={{
              borderRadius: '24px',
              height: '48px',
              fontSize: '18px',
              fontWeight: '400',
              border: '2px solid #232429',
              '& .MuiSvgIcon-root': {
                color: 'black'
              }
            }}
            MenuItemSx={{}}
          />
        )}
        <Flex ml={mdDown ? 0 : 4}>
          <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: 'none',
                padding: mdDown ? '0 16px' : '0 37px',
                height: '48px',
                borderRadius: '80px',
                background: '#3B3C45'
              }}
              onClick={toRegister}
            >
              {t('Register')}
            </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    tooltips: {
      width: '244px',
      margin: '0 auto',

      '& img': {
        width: '244px',
        hegiht: '244px',
        borderRadius: '16px'
      },

      '& p': {
        fontFamily: 'Inter',
        color: '#5F606D',
        textAlign: 'center',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '16px',
        margin: '8px auto'
      }
    },
    title: {
      fontFamily: 'Inter',
      color: '#18181B',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '20px',
      margin: '8px auto'
    },
    link: {
      display: 'flex',
      padding: '4px 8px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      background: '#FFF',
      marginBottom: '16px'
    },
    text: {
      width: '195px',
      fontFamily: 'Inter',
      color: '#18181B',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      marginRight: '8px'
    },
    icon: {
      width: '24px',
      height: '24px',
      background: 'url(/images/icons/copy.svg) center no-repeat',
      cursor: 'pointer'
    }
  })
);

export default observer(InterHead);
