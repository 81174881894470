import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { AppTheme } from "styles/theme";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import Avatar from "@material-ui/core/Avatar";
import Popover from "@material-ui/core/Popover";
import Flex from "components/common/Flex";
import { LogoutSvg } from "assets/icons";
import useDepsContainer from "hooks/useDepsContainer";

function UserAvatar({ userInfo }) {
  const styles = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const { uiStore } = useDepsContainer();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const thumbnail = userInfo?.profile?.photos?.[0]?.thumbnail || ""

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const logout = () => {
    localStorage.removeItem("token");
    uiStore.isLogin = false
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Flex>
      <Flex aria-describedby={id} cursorPointer onClick={handleClick}>
        <Avatar srcSet={thumbnail} style={{ width: "36px", height: "36px" }} />
      </Flex>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            marginTop: "10px",
            borderRadius: "8px",
            boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
          }
        }}
      >
        <Flex
          px={2}
          py={2}
          style={{
            width: "416px",
          }}
          column
        >
          <Flex pb={4} style={{
            borderBottom: "1px solid #DDDFEE",
          }} centerY>
            <Avatar srcSet={thumbnail} style={{ width: "56px", height: "56px" }} />
            <Flex ml={2} column>
              <Flex style={{
                fontWeight: 700,
                fontSize: "20px",
                color: "black",
              }}>
                {userInfo?.nickname || "nickname"}
              </Flex>
              <Flex style={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#5F606D",
              }}>
                {userInfo?.profile?.about || "-" }
              </Flex>
            </Flex>
          </Flex>
          <Flex pt={4} pb={2} cursorPointer style={{
            fontWeight: 600,
            fontSize: "18px",
            color: "#E85130",
          }} onClick={logout}>
            <Flex mr={3}><LogoutSvg /></Flex>
            Logout
          </Flex>
        </Flex>
      </Popover>
    </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) => createStyles({}));

export default observer(UserAvatar);
