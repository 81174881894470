import { useMediaQuery, useTheme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { LogoTextSvg } from 'assets/icons';
import PageFooter from 'components/Footer';
import Header from 'components/Header';
import Flex from 'components/common/Flex';
import Text from 'components/common/Text';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { AppTheme } from 'styles/theme';

interface CookiePolicyPageProps {}

const CookiePolicyPage = (props: CookiePolicyPageProps) => {
  const styles = useStyles(props);
  const history = useHistory();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Flex
      width="100vw"
      height={'100vh'}
      className={styles.pageContainer}
      bgcolor={'rgba(255, 255, 255)'}
      column
      centerY
    >
      <Header bgcolor={'rgba(255, 255, 255)'} />

      <Flex width={'100%'} flex={1} column mb={mdDown ? 0 : 15} py={4} px={mdDown ? 6 : 2} maxWidth={1200}>
        <Flex width={'100%'} justifyContent={'flex-end'}>
          <LogoTextSvg style={{ width: 120 }} />
        </Flex>

        <Text
          style={{
            fontWeight: 700,
            fontSize: 48
          }}
          color={`#E85130`}
        >
          Cookie Policy for Pumpkin Dating
        </Text>
        <Text my={1} color="#5F606D">
          Effective Date: [13th March 2023]
        </Text>

        <Text component={'span'} color="#18181B" whiteSpace={'break-spaces'} mt={2} style={{ textAlign: 'justify' }}>
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            1. Introduction
          </Text>
          {`
This Cookie Policy explains how Pumpkin ("we," "us," or "our") uses cookies and similar technologies to recognize you when you visit our website, mobile application, or other online services (collectively, the "Service"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            2. What are cookies?
          </Text>
          {`
Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies serve a number of purposes, such as allowing a website to recognize your device, remembering your preferences, and tracking your activities on the website.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            3. What cookies do we use?
          </Text>
          {`
We use the following types of cookies:
 Essential cookies: These cookies are necessary for the Service to function properly. They help you navigate the Service and use its features, such as accessing secure areas of the Service. Without these cookies, the Service would not work properly.
 Analytics cookies: These cookies help us understand how you use the Service by collecting information about your device, such as the type of device, operating system, and browser, as well as information about how you interact with the Service, such as the pages you visit, the features you use, and the time you spend on the Service. We use this information to improve the Service and to provide you with a better experience.
 Advertising cookies: These cookies are used to deliver advertisements that are more relevant to you and your interests. They are also used to limit the number of times you see an advertisement and to help measure the effectiveness of an advertising campaign.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            4. How long do cookies stay on my device?
          </Text>
          {`
Some cookies are erased when you close your browser (session cookies), while others remain on your device until they expire or you delete them from your browser or device (persistent cookies).

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            5. How do I control cookies?
          </Text>
          {`
You can control cookies by adjusting your browser settings. Most browsers allow you to refuse cookies or to delete cookies that have already been placed on your device. However, if you block or delete cookies, some features of the Service may not work properly.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            6. Changes to this Cookie Policy
          </Text>
          {`
We may update this Cookie Policy from time to time. If we make any material changes to this Cookie Policy, we will notify you by posting the updated policy on our website or by sending you an email or other notification. Your continued use of the Service after we make changes to this Cookie Policy will constitute your acceptance of those changes.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            7. Contact Us
          </Text>
          {`
If you have any questions about our use of cookies, please contact us at `}
          <Text component={'span'} style={{ fontWeight: 'bold' }}>
            support@pumpkin.xyz
          </Text>
          {`.

`}
        </Text>
      </Flex>

      <Flex className={styles.footerWrap} mt={mdDown ? 8 : 1.6}>
        <PageFooter isHome />
      </Flex>
    </Flex>
  );
};

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    pageContainer: {
      overflowX: 'hidden',
      overflowY: 'auto'
    },
    sessionWrap: {
      width: '100%'
    },
    footerWrap: {
      width: '100%'
    }
  })
);

export default observer(CookiePolicyPage);
