import * as React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { LogoTextSvg } from 'assets/icons';
import PageFooter from 'components/Footer';
import Header from 'components/Header';
import Flex from 'components/common/Flex';
import Text from 'components/common/Text';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { AppTheme } from 'styles/theme';

interface TermsPageProps {}

const TermsPage = (props: TermsPageProps) => {
  const styles = useStyles(props);
  const history = useHistory();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Flex
      width="100vw"
      height={'100vh'}
      className={styles.pageContainer}
      bgcolor={'rgba(255, 255, 255)'}
      column
      centerY
    >
      <Flex position={'absolute'} top={0} left={0} width={'100%'}>
        <Header bgcolor={'rgba(255, 255, 255)'} />
      </Flex>

      <Flex width={'100%'} flex={1} column mb={mdDown ? 0 : 15} py={4} px={mdDown ? 6 : 2} maxWidth={1200}>
        {/* <Flex width={'100%'} justifyContent={'flex-end'}>
          <LogoTextSvg style={{ width: 120 }} />
        </Flex> */}

        <Text
          style={{
            fontWeight: 700,
            fontSize: 48,
            marginTop: 40
          }}
          color={`#E85130`}
        >
          Terms and Conditions
        </Text>
        <Text color="#5F606D" my={1}>
          Effective Date: March 4, 2023
        </Text>

        <Text component={'span'} color="#18181B" whiteSpace={'break-spaces'} mt={2} style={{ textAlign: 'justify' }}>
          {`Welcome to Pumpkin, a dating app that uses blockchain technology to provide a secure and transparent platform for individuals to connect with each other. These Terms of Use (“`}
          <Text component={'span'} style={{ fontWeight: 'bold' }}>
            Terms
          </Text>
          {`”) govern your access to and use of the Pumpkin app and website (together, the “`}
          <Text component={'span'} style={{ fontWeight: 'bold' }}>
            Service
          </Text>
          {`”), and any information, text, graphics, photos, or other materials uploaded, downloaded, or appearing on the Service (collectively referred to as “`}{' '}
          <Text component={'span'} style={{ fontWeight: 'bold' }}>
            Content
          </Text>
          {`”).
Please read these Terms carefully before using the Service. By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use the Service.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            1. Eligibility
          </Text>
          {`
To use the Service, you must be at least 18 years old or the age of majority in your jurisdiction, whichever is greater. By accessing or using the Service, you represent and warrant that you are at least 18 years old or the age of majority in your jurisdiction, whichever is greater.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            2. Account Registration
          </Text>
          {`
To use certain features of the Service, you may need to register for an account. When you register for an account, you will be asked to provide certain information, such as your name, email address, and date of birth. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
You are responsible for maintaining the confidentiality of your account and password and private key and for restricting access to your account, and you agree to accept responsibility for all activities that occur under your account or password. You may not share your account or password or private key with anyone, and you agree to notify us immediately of any unauthorized use of your account or password or private key or any other breach of security.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            3. User Conduct
          </Text>
          {`
You agree to use the Service only for lawful purposes and in accordance with these Terms. You are solely responsible for your conduct on the Service and any Content that you submit, post, or display on or via the Service.
You agree not to use the Service:
In any way that violates any applicable federal, state, local, or international law or regulation.
For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.
To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail,” “chain letter,” “spam,” or any other similar solicitation;
To impersonate or attempt to impersonate Pumpkin, a Pumpkin employee, another user, or any other person or entity.
To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Service, or which, as determined by us, may harm Pumpkin or users of the Service or expose them to liability.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            4. Content Standards
          </Text>
          {`
You agree to comply with the following Content Standards when submitting, posting, or displaying Content on or via the Service:
Your Content must not be defamatory, obscene, pornographic, indecent, abusive, offensive, harassing, or otherwise objectionable.
Your Content must not promote or glorify illegal activity, violence, or hatred against any individual or group.
Your Content must not infringe any patent, trademark, trade secret, copyright, or other intellectual property rights of any other person or entity.
Your Content must not contain any viruses, worms, or other harmful components.
Your Content must not be used to collect or harvest any personally identifiable information, including account names, from the Service.
Your Content must not be used to contact or harass other users of the Service.
We reserve the right to remove any Content that violates these Content Standards or is otherwise objectionable, in our sole discretion.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            5. User Content
          </Text>
          {`
The Service may allow you to submit , post, or display Content, including but not limited to profile information, photos, messages, and other materials (collectively, “User Content”). You retain all rights in, and are solely responsible for, the User Content you submit, post, or display on or via the Service.
By submitting, posting, or displaying User Content on or via the Service, you grant us a non-exclusive, royalty-free, transferable, sublicensable, worldwide license to use, copy, modify, create derivative works based on, distribute, publicly display, publicly perform, and otherwise exploit in any manner such User Content in all formats and distribution channels now known or hereafter devised (including in connection with the Service and our business and on third-party sites and services), without further notice to or consent from you, and without the requirement of payment to you or any other person or entity.
You represent and warrant that: (i) you either are the sole and exclusive owner of all User Content or you have all rights, licenses, consents, and releases that are necessary to grant to us the rights in such User Content, as contemplated under these Terms; and (ii) neither the User Content, nor your submission, posting, or displaying of such User Content, nor the exercise of the rights granted herein, will infringe, misappropriate, or violate any third-party patent, copyright, trademark, trade secret, moral right, or other proprietary or intellectual property right, or any rights of publicity or privacy, or result in the violation of any applicable law or regulation.
You understand that when using the Service, you will be exposed to User Content from a variety of sources, and that we are not responsible for the accuracy, usefulness, safety, or intellectual property rights of or relating to such User Content. You further understand and acknowledge that you may be exposed to User Content that is inaccurate, offensive, indecent, or objectionable, and you agree to waive, and hereby do waive, any legal or equitable rights or remedies you have or may have against us with respect thereto.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            6. Third-Party Services
          </Text>
          {`
The Service may contain links to third-party websites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that we are not responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such third-party websites or services.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            7. Intellectual Property
          </Text>
          {`
The Service and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by Pumpkin or its licensors and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
These Terms permit you to use the Service for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Service, except as follows:
Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.
You may store files that are automatically cached by your Web browser for display enhancement purposes.
You may print or download one copy of a reasonable number of pages of the Service for your own personal, non-commercial use and not for further reproduction, publication, or distribution.

If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, and not for further reproduction, publication, or distribution. If we provide social media features with certain content, you may take such actions as are enabled by such features.
You must not:
Modify copies of any materials from this site.
Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text.
Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site;
Access or use for any commercial purposes any part of the Service or any services or materials available through the Service.
If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Service in breach of the Terms, your right to use the Service will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. Any unauthorized use may also violate applicable laws, including without limitation copyright and trademark laws and applicable communications regulations and statutes.
Except as expressly provided herein, we do not grant you any express or implied rights, and all rights in and to the Service are retained by us.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            8. User Conduct
          </Text>
          {`
You understand and agree that you are solely responsible for compliance with any and all laws, rules, regulations, and Tax obligations that may apply to your use of the Service. In connection with your use of the Service, you may not and you agree that you will not:
violate any local, state, provincial, national, or other law or regulation, or any order of a court, including, without limitation, zoning restrictions and Tax regulations;
use manual or automated software, devices, scripts, robots, backdoors, or other means or processes to access, “scrape,” “crawl,” or “spider” any web pages or other services contained in the Service;
access or use our Service to use, expose, or allow to be used or exposed, any Content that is illegal or otherwise objectionable or offensive;
infringe the rights of any person or entity, including without limitation, their intellectual property, privacy, publicity or contractual rights;
interfere with or damage our Service, including, without limitation, through the use of viruses, cancel bots, Trojan horses, harmful code, flood pings, denial-of-service attacks, packet or IP spoofing, forged routing or electronic mail address information, or similar methods or technology;
use our Service to transmit, distribute, post, or submit any information concerning any other person or entity, including without limitation, photographs of others without their permission, personal contact information or credit, debit, or account numbers;
use our Service in connection with the distribution of unsolicited commercial email ("spam") or advertisements unrelated to your personal use of the Service, including without limitation promotions or advertisements for products or services;
"stalk" or harass any other user of our Service or collect or store any personally identifiable information about any other user other than for purposes of establishing contact as part of a legitimate connection on our Service;
use automated scripts to collect information from or otherwise interact with the Service;
impersonate any person or entity or otherwise misrepresent yourself or your affiliation with any person or entity;
solicit personal information from anyone under the age of 18 or solicit passwords or personally identifying information for commercial or unlawful purposes;
use or attempt to use another user's account without authorization from that user and Pumpkin;
engage in any harassing, intimidating, predatory, or discriminatory conduct; or
encourage or enable any other individual to do any of the foregoing.

We reserve the right to investigate and prosecute violations of any of the above and/or involve and cooperate with law enforcement authorities in prosecuting users who violate these Terms. You acknowledge that we have no obligation to monitor your access to or use of the Service, but we have the right to do so for the purpose of operating the Service, to ensure your compliance with these Terms, or to comply with applicable law or the order or requirement of a court, administrative agency, or other governmental body.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            9. Disclaimers; Limitations of Liability
          </Text>
          {`
`}
          <Text component={'span'} style={{ fontWeight: 'bold' }}>
            9.1. Disclaimer of Warranties
          </Text>
          {`
You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Service will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data.
We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses, or other technologically harmful material that may infect your computer equipment, computer programs, data, or other proprietary material due to your use of the Service or any services or items obtained through the Service or to your downloading of any material posted on it, or on any website linked to it.
YOUR USE OF THE SERVICE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE IS AT YOUR OWN RISK. THE SERVICE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER PUMPKIN NOR ANY PERSON ASSOCIATED WITH PUMPKIN MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICE. WITHOUT LIMITING THE FOREGOING, NEITHER PUMPKIN NOR ANYONE ASSOCIATED WITH PUMPKIN REPRESENTS OR WARRANTS THAT THE SERVICE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
`}
          <Text component={'span'} style={{ fontWeight: 'bold' }}>
            9.2. Limitation on Liability
          </Text>
          {`
TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL PUMPKIN, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE SERVICE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            10. Indemnification
          </Text>
          {`
You agree to defend, indemnify, and hold harmless Pumpkin, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your use of the Service, including, but not limited to, any User Content you submit, post, or transmit through the Service, your use of the Service's content, services, and products, your connection to the Service, your violation of any third-party rights or applicable laws, rules, or regulations, or your use of any third-party services or products.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            11. Termination
          </Text>
          {`
We may terminate your access to the Service or your account at any time, with or without cause or notice, which may result in the forfeiture and destruction of all information associated with your account. All provisions of these Terms that by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            12. Governing Law and Jurisdiction
          </Text>
          {`
These Terms and your use of the Service shall be governed by and construed in accordance with the laws of the Cayman Islands, without regard to its conflict of law provisions.
Any legal suit, action, or proceeding arising out of, or related to, these Terms or the Service shall be instituted exclusively in the courts of the Cayman Islands. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            13. Waiver and Severability
          </Text>
          {`
Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.
If any provision of these Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            14. Entire Agreement
          </Text>
          {`
These Terms and our Privacy Policy constitute the entire agreement between you and Pumpkin regarding the use of the Service, superseding any prior agreements between you and Pumpkin relating to the Service.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            15. Contact Information
          </Text>
          {`
If you have any questions about these Terms, please contact us at `}
          <Text component={'span'} style={{ fontWeight: 'bold' }}>
            support@pumpkin.xyz
          </Text>
          {`.
`}
        </Text>
      </Flex>

      <Flex>
        <PageFooter isHome />
      </Flex>
    </Flex>
  );
};

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    pageContainer: {
      overflowX: 'hidden',
      overflowY: 'auto'
    },
    sessionWrap: {
      width: '100%'
    },
    footerWrap: {
      width: '100%'
    }
  })
);

export default observer(TermsPage);
