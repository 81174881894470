import { observer } from "mobx-react";
import { AppTheme, Colors } from "styles/theme";
import React, { useState, useMemo, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Flex from "components/common/Flex";
import Text from "components/common/Text";
import useDepsContainer from "hooks/useDepsContainer";
import AgeCalculator from "./AgeCalculator";
import {GradeSvg, VipSvg} from "assets/icons/index";

function InfoLabel({ userInfo }) {
  const theme = useTheme();
  const styles = useStyles();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();

  const { uiStore } = useDepsContainer();
  const { t } = useTranslation();
  const cachedT = useMemo(() => t, [t, uiStore.language]);

  return (
      <Flex display={"flex"} alignItems={"center"}>
          <Flex className={styles.title} mr={2} fontSize={mdDown ? "24px" : "32px"}>
              {userInfo?.nickname}
          </Flex>
          <Flex className={styles.title2} mr={2} fontSize={mdDown ? "18px" : "24px"}>
              <AgeCalculator birthDate={userInfo?.profile?.birthday} />
          </Flex>
          <Flex
              mr={2}
              bgcolor={"black"}
              style={{
                  borderRadius: "100px",
                  color: "white",
                  padding: "0 6px",
              }}
              center
          >
              <Flex mr={1}>
                  <GradeSvg width={12} height={12} />
              </Flex>
              <Text>{userInfo?.level}</Text>
          </Flex>
          <Flex mr={2}>
              <VipSvg />
          </Flex>
      </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
      title: {
          fontWeight: 700
      },
      title2: {
          fontWeight: 400,
      },
  })
);

export default observer(InfoLabel);
