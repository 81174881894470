import React from "react";
import { observer } from "mobx-react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

type AlertMessageProps = {
  open: boolean;
  onClose: () => void;
  message: string;
  severity: "success" | "error" | "info" | "warning";
  vertical?: 'top' | 'bottom';
  horizontal?: 'left' | 'center' | 'right';
};

function AlertMessage({
  open,
  onClose,
  message,
  severity = "info",
  vertical = "top",
  horizontal = "center",
}: AlertMessageProps) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={1000}
      anchorOrigin={{ vertical, horizontal }}
      onClose={onClose}
      key={vertical + horizontal}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={onClose}
        severity={severity}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
}

export default observer(AlertMessage);
