import { observer } from "mobx-react";
import { AppTheme, Colors } from "styles/theme";
import React, { useState, useMemo, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Flex from "components/common/Flex";
import Text from "components/common/Text";
import {
  AcademicCapSvg,
  BriefcaseSvg,
  BuildngOfficeSvg,
  GenderSvg,
  HeightSvg,
  HomeSvg,
} from "assets/icons/index";
import useDepsContainer from "hooks/useDepsContainer";

function InfoIntro({ profile }) {
  const theme = useTheme();
  const styles = useStyles();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();

  const { uiStore } = useDepsContainer();
  const { t } = useTranslation();
  const cachedT = useMemo(() => t, [t, uiStore.language]);

  const [userIntro, setUIntro] = useState<any[]>([]);

  useEffect(() => {
    setUIntro([
      {
        icons: <GenderSvg width={16} height={16} />,
        cont: profile?.gender === 1 ? cachedT("Man") : cachedT("Woman"),
      },
      {
        icons: <AcademicCapSvg width={16} height={16} />,
        cont: profile?.university || "-",
      },
      {
        icons: <HeightSvg width={16} height={16} />,
        cont: profile?.height ? `${profile.height}cm` : "-",
      },
      {
        icons: <BriefcaseSvg width={16} height={16} />,
        cont: profile?.jobTitle || "-",
      },
      {
        icons: <HomeSvg width={16} height={16} />,
        cont: profile?.hometown || "-",
      },
      {
        icons: <BuildngOfficeSvg width={16} height={16} />,
        cont: profile?.company || "-",
      },
    ]);
  }, []);

  return (
    <Flex
      pt={3}
      pb={1}
      width={"100%"}
      display="flex"
      flexWrap={"wrap"}
      style={
        mdDown
          ? {}
          : {
              borderTop: "1px solid #DDDFEE",
              borderBottom: "1px solid #DDDFEE",
            }
      }
      column={mdDown}
    >
      {userIntro.map((item, index) => {
        return (
          <Flex
            key={`intro-${index}`}
            className={styles.label}
            color={"#232429"}
            flexBasis={mdDown ? "" : "50%"}
            mb={2}
            centerY
          >
            <Flex mr={1}>{item.icons}</Flex>
            <Text>{item.cont}</Text>
          </Flex>
        );
      })}
    </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    label: {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
    },
  })
);

export default observer(InfoIntro);
