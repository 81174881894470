import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { useSetState } from 'ahooks';
import Flex from 'components/common/Flex';
import { TipSvg } from 'assets/icons';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { AppTheme } from 'styles/theme';
import { inviteUU, tagUU } from 'api/users';
import { createStyles, makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SwiperInvite from './components/SwiperInvite';
import InterButton from './components/InterButton';
import InterHead from './components/InterHead';

import InfoIntro from './components/InfoIntro';
import InfoTags from './components/InfoTags';
import InfoLabel from './components/InfoLabel';
import InfoLocation from './components/InfoLocation';
import InfoAbout from './components/InfoAbout';
import InfoPrompts from './components/InfoPrompts';

import { useHistory } from 'react-router-dom';
import useDepsContainer from 'hooks/useDepsContainer';
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import './components/inviteHeight.css';
import { isBrowser, isMobile } from 'react-device-detect';

interface InvitePageProps {}

function InvitePage(props: InvitePageProps) {
  const theme = useTheme();
  const styles = useStyles(props);
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();

  const location = useLocation();
  const usernameInviter = location.pathname.split('/')?.[1] || null;

  const { uiStore } = useDepsContainer();
  const { t } = useTranslation();
  const cachedT = useMemo(() => t, [t, uiStore.language]);

  const [loading, setLoading] = useState(false);
  const [flipped, setFlipped] = useState(false);
  const [notExist, setNotExist] = useState<any>('info');
  const [username, setUsername] = useState(usernameInviter);
  const [userInfo, setUserInfo] = useSetState<any>({});

  const [userTitle, setUserTitle] = useState('');
  const [userConciseUserId, setUserConciseUserId] = useState<string>('');
  const [userPhotos, setUserPhotos] = useState<any[]>([]);
  const [userAbout, setUserAbout] = useState<string>('');
  const [userProfile, setUserProfile] = useState<any>({});
  const [tagsLangItem, setTagsLangItem] = useState<any>({});
  const [userConnectTimes, setUserConnectTimes] = useState<string | null>(null);
  const [userConnectStatus, setUserConnectStatus] = useState<string | null>(null);
  const [kolUrl, setKolUrl] = useState('');

  useEffect(() => {
    if (usernameInviter) {
      // getKol(usernameInviter).then(res => {
      //   if (res?.data?.code === 200) {
      //     if (res?.data?.data) {
      //       setKolUrl(res?.data?.data);
      //     } else {
      //       setKolUrl('https://app.adjust.com/176ugajm');
      //     }
      //   } else {
      //     setKolUrl('https://app.adjust.com/176ugajm');
      //   }
      // });
      setUsername(usernameInviter);
      setLoading(true);
      setNotExist('info');
      inviteUU(usernameInviter)
        .then(res => {
          if (res?.data?.code === 20004) {
            setNotExist('notexist');
            return;
          }
          if (res?.data?.code !== 200) {
            return;
          }
          setNotExist('success');
          const data = res?.data?.data;
          setUserInfo(data);
          setUserConciseUserId(data?.conciseUserId)

          const title = `${data?.nickname} (@${data?.username}) | Pumpkin Mee`;
          document.title = title;
          setUserTitle(title);

          tagUU(uiStore.language).then(res => {
            setTagsLangItem(res?.data || {});
          });

          setUserProfile(data?.profile || {});

          setUserPhotos(data?.profile?.photos || []);
          setUserAbout(data?.profile?.about || '');
          setUserConnectTimes(data?.connectTimes);
          setUserConnectStatus(
            data?.connectStatus === 2
              ? cachedT('RecentlyActive')
              : data?.connectStatus === 0
              ? cachedT('Active')
              : cachedT('Offline')
          );
        })
        .finally(() => setLoading(false));
    }
  }, [usernameInviter]);

  function toRegister(event?: any, title?: string) {
    if (event) event.preventDefault();
    if (isMobile) {
      window.location.href = `${process.env.REACT_APP_DOWN_URL}${username}?title=${title || userTitle}&id=${userConciseUserId}`;
      return;
    }
    //history.push(`/register?ref=${username}`);
    window.open('https://app.adjust.com/176ugajm');
  }

  function toRegisterNot() {
    history.push(`/register`);
  }

  const handleFlip = () => {
    setFlipped(!flipped);
  };

  const NotExhibit = () => {
    return (
      <>
        The current user query does not exist, you can try{' '}
        <Flex
          color={'#e8a50d'}
          style={{
            cursor: 'pointer',
            padding: '0 5px',
            display: 'inline-block'
          }}
          onClick={toRegisterNot}
        >
          to register
        </Flex>{' '}
        directly directly?
      </>
    );
  };

  const MainBody = () => {
    return (
      <Flex mt={10} width={992} position={'relative'}>
        <Flex
          className={styles.bgill}
          style={{
            width: '760px',
            top: 0,
            zIndex: 0
          }}
        ></Flex>
        <Flex
          className={styles.bgill}
          style={{
            width: '854px',
            top: '1rem',
            zIndex: 1
          }}
        ></Flex>

        {notExist === 'notexist' && (
          <Flex
            mt={10}
            bgcolor={'bg'}
            zIndex={3}
            width={'100%'}
            height={'300px'}
            overflow={'hidden'}
            className={styles.card}
            center
          >
            <NotExhibit />
          </Flex>
        )}

        {notExist === 'success' && (
          <Flex
            mt={10}
            bgcolor={'bg'}
            zIndex={3}
            width={'100%'}
            height={494}
            overflow={'hidden'}
            className={styles.card}
          >
            <Flex width={390} overflow={'hidden'} center>
              <SwiperInvite photos={userPhotos} toRegister={toRegister} />
            </Flex>

            <Flex flex={1} py={4} px={4} ml={'34px'} position={'relative'} column>
              <Flex width={'100%'} display="flex" justifyContent="space-between">
                <Flex>
                  <InfoLabel userInfo={userInfo} />
                </Flex>
                <Flex>
                  <IconButton onClick={toRegister}>
                    <MoreHorizIcon />
                  </IconButton>
                </Flex>
              </Flex>

              <Flex mt={1}>
                <InfoLocation
                  userInfo={userInfo}
                  userConnectTimes={userConnectTimes}
                  userConnectStatus={userConnectStatus}
                />
              </Flex>

              <Flex className={styles.corsson} height={'325px'} column>
                <Flex mt={3}>
                  <InfoIntro profile={userProfile} />
                </Flex>

                <Flex mt={4}>
                  <InfoAbout userAbout={userAbout} />
                </Flex>

                <Flex mt={4}>
                  <InfoPrompts profile={userProfile} tagsLangItem={tagsLangItem} />
                </Flex>

                <Flex mb={4}>
                  <InfoTags profile={userProfile} tagsLangItem={tagsLangItem} />
                </Flex>
              </Flex>

              <Flex
                center
                style={{
                  position: 'absolute',
                  width: '100%',
                  bottom: 20
                }}
              >
                <InterButton downTitle={userTitle} kolUrl={kolUrl} userConciseUserId={userConciseUserId}/>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    );
  };

  const MoveBody = () => {
    return (
      <Flex mt={0} py={2} px={2} width={'100%'} className={'MoveBodyHeight'} center>
        {notExist === 'notexist' && (
          <Flex display={'inline-block'} textAlign={'center'}>
            <NotExhibit />
          </Flex>
        )}

        {notExist === 'success' && (
          <Flex
            width={'100%'}
            height={'100%'}
            style={{
              borderRadius: '16px',
              overflow: 'hidden',
              position: 'relative'
            }}
          >
            <Flex className={styles.moveCard} onClick={handleFlip}>
              {!flipped && <SwiperInvite photos={userPhotos} toRegister={toRegister} />}
              {flipped && (
                <Flex
                  bgcolor={'#F3F4FC'}
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '16px',
                    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.08)'
                  }}
                  px={2}
                >
                  <Flex width={'100%'} height={'100%'} py={2} px={1} column>
                    <Flex width={'100%'}>
                      <Flex mr={2}>
                        <Avatar
                          srcSet={userInfo?.profile?.photos?.[0]?.thumbnail || ''}
                          style={{
                            width: '36px',
                            height: '36px',
                            border: '2px solid #4E4762'
                          }}
                        />
                      </Flex>
                      <InfoLabel userInfo={userInfo} />
                    </Flex>

                    <Flex mt={1}>
                      <InfoLocation
                        userInfo={userInfo}
                        userConnectTimes={userConnectTimes}
                        userConnectStatus={userConnectStatus}
                      />
                    </Flex>

                    <Flex
                      mt={3}
                      className={styles.corsson}
                      style={{ borderRadius: '8px' }}
                      height={'calc(100% - 150px)'}
                      column
                    >
                      <Flex className={styles.radius} column>
                        <Flex
                          style={{
                            fontWeight: 700,
                            fontSize: '14px',
                            color: '#6B6D7B'
                          }}
                        >
                          {cachedT('myBasics')}
                        </Flex>
                        <InfoIntro profile={userProfile} />
                      </Flex>

                      <Flex mt={2} className={styles.radius}>
                        <InfoPrompts profile={userProfile} tagsLangItem={tagsLangItem} />
                      </Flex>

                      <Flex mt={2} className={styles.radius}>
                        <InfoAbout userAbout={userAbout} />
                      </Flex>

                      <Flex mt={2} className={styles.radius}>
                        <InfoTags profile={userProfile} tagsLangItem={tagsLangItem} />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </Flex>

            <Flex
              px={3}
              py={5}
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                zIndex: 11
              }}
              column
            >
              {!flipped && (
                <Flex onClick={handleFlip} cursorPointer column>
                  <Flex mb={1} color={'white'} display={'flex'} alignItems={'center'}>
                    <InfoLabel userInfo={userInfo} />
                  </Flex>
                  <Flex mb={4} color={'white'}>
                    <InfoLocation
                      userInfo={userInfo}
                      userConnectTimes={userConnectTimes}
                      userConnectStatus={userConnectStatus}
                      timesPropsColor={'white'}
                      timesPropsStyle={{
                        color: 'white',
                        margin: '0 10px'
                      }}
                    />
                  </Flex>
                </Flex>
              )}
              <Flex width={'100%'}>
                <InterButton downTitle={userTitle} kolUrl={kolUrl} userConciseUserId={userConciseUserId}/>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    );
  };

  return (
    <Flex className={`${styles.bg} bgHeight`} column centerY>
      <InterHead downTitle={userTitle} kolUrl={kolUrl} conciseUserId={userConciseUserId}/>
      {mdDown ? <MoveBody /> : <MainBody />}
      <div id="fixed-element" style={{ position: 'fixed', top: 0, left: 0, height: '100vh' }} />
    </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    bg: {
      color: 'black',
      backgroundColor: '#FBFBFB',
      width: '100%',
      height: '100vh'
    },
    bgill: {
      height: '100px',
      background: '#AAACBB',
      opacity: 0.5,
      borderRadius: '16px',
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, 0)'
    },
    card: {
      display: 'flex',
      backgroundColor: 'white',
      borderRadius: '16px',
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)'
    },
    moveCard: {
      width: '100%',
      height: '100%',
      perspective: '1000px',
      position: 'relative'
    },
    corsson: {
      width: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '4px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#DDDFEE',
        borderRadius: '8px'
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
        WebkitBoxShadow: '0 0 4px black !important'
      }
    },
    radius: {
      borderRadius: '8px',
      background: 'white',
      padding: 10
    }
  })
);

export default observer(InvitePage);
