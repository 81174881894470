import React, { useState, useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSetState } from "ahooks";
import { observer } from "mobx-react";
import Flex from "components/common/Flex";
import Text from "components/common/Text";
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import { AppTheme, Colors } from "styles/theme";
import { LogoTextSvg } from "assets/icons";
import { accountPreviewRegister, accountPrivyPreviewRegister, userCheck } from "api/users";
import { useHistory } from "react-router-dom";
import { usePrivy } from '@privy-io/react-auth'

import useDepsContainer from "hooks/useDepsContainer";
import { useTranslation } from "react-i18next";
import { isBrowser, isMobile } from 'react-device-detect';

import AlertMessage from "components/common/AlertMessage";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

interface RegisterFormProps {}

type BtnMessage = {
  open: boolean;
  message: string;
  severity: "success" | "error" | "info" | "warning";
};

const RegisterForm = (props: RegisterFormProps) => {
  const styles = useStyles(props);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let RefInviter = searchParams.get("conciseUserId");
  const { ready, authenticated, login, getAccessToken, user } = usePrivy()
  // Disable login when Privy is not ready or the user is already authenticated
  const disableLogin = !ready || (ready && authenticated)
  const [clickReg, setClickReg] = useState(false);

  function privyLogin() {
    if(formik.values.inviter){
      userCheck(formik.values.inviter).then(res => {
        if (res.data.code === 200) {
          if(res.data.data === true){
            if (ready && !authenticated) {
              login();
              setClickReg(true);
            } else if (ready && authenticated) {
              setClickReg(true);
            }
          } else {
            setBtnMessage({
              open: true,
              message: cachedT('InvitationCodeInvalid'),
              severity: "error",
            });
          }
        }
      })
    } else {
      if (ready && !authenticated) {
        login();
        setClickReg(true);
      } else if (ready && authenticated) {
        setClickReg(true);
      }
    }
  }

  useEffect(() => {
    const asyncEffect = async () => {
      if (authenticated && clickReg) {
        const userId = user?.id
        await accountPrivyPreviewRegister({
          privyId: user?.id,
          inviter: formik.values.inviter
        })
          .then((res) => {
            const data = res?.data;
            if (data.code === 200 && data.data) {
              if (isMobile) {
                toDownload()
                setTimeout(() => { toDownload() }, 1000);
              } else {
                setBtnFinMessage({
                  open: true,
                  message: cachedT("successfulRegistrationMessage"),
                  severity: "success",
                });
              }
            } else {
              setBtnMessage({
                open: true,
                message: data?.message,
                severity: "error",
              });
            }
          })
          .catch(() => {
            setBtnMessage({
              open: true,
              message: cachedT("serverExceptionMessage"),
              severity: "error",
            });
          })
          .finally(() => setLoading(false))
      }
    }
    asyncEffect()
  }, [ready, authenticated, clickReg, user])

  useEffect(() => {
    if (RefInviter) {
      sessionStorage.setItem("RefInviter", RefInviter);
    } else {
      RefInviter = sessionStorage.getItem("RefInviter");
      if (RefInviter) {
        searchParams.set("ref", RefInviter);
        history.replace({
          search: searchParams.toString(),
        });
      }
    }
  }, [location.search]);


  const { uiStore } = useDepsContainer();
  const { t, i18n } = useTranslation();
  const cachedT = useMemo(() => t, [t, uiStore.language]);

  const [btnMessage, setBtnMessage] = useSetState<BtnMessage>({
    open: false,
    message: "",
    severity: "info",
  });

  const [btnFinMessage, setBtnFinMessage] = useSetState<BtnMessage>({
    open: false,
    message: "",
    severity: "info",
  });

  function toDownload() {
    const title = `${RefInviter} (@${RefInviter}) | Pumpkin Mee`;
    window.location.href = `${process.env.REACT_APP_DOWN_URL}${title}`;
  }


  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      confirmPassword: "",
      inviter: "",
    },
    validationSchema: yup.object().shape({
      username: yup
        .string()
        .required(cachedT("usernameIsRequired"))
        .min(4, cachedT("usernameTooShort"))
        .max(20, cachedT("usernameTooLong"))
        .matches(
          /^[a-zA-Z0-9_-]{4,20}$/,
          cachedT("usernameIncorrectFormat")
        ),
      password: yup
        .string()
        .required(cachedT("passwordIsRequired"))
        .min(6, cachedT("passwordTooShort"))
        .max(40, cachedT("password_too_long", { short: 3, long: 40 }))
        .matches(/^[A-Za-z0-9 !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{6,40}$/, cachedT("passwordIncorrectFormat")),
      confirmPassword: yup
        .string()
        .required(cachedT("confirmPasswordIsRequired"))
        .oneOf([yup.ref("password")], cachedT("passwordsMustMatch")),
      inviter: yup
        .string()
        // .required(t("referral_code_required", "Referral code is required"))
        .min(1, cachedT("invalidReferralCode"))
        .max(20, cachedT("invalidReferralCode")),
    }),
    onSubmit: async (values) => {
      if (loading) {
        return;
      }
      setLoading(true);

      const { username, password, inviter } = values;

      await accountPreviewRegister({
        username,
        password,
        inviter,
      })
        .then((res) => {
          const data = res?.data;

          if (data.code === 200) {
            if (isMobile) {
              toDownload()
              setTimeout(() => { toDownload() }, 1000);
            } else {
              setBtnFinMessage({
                open: true,
                message: cachedT("successfulRegistrationMessage"),
                severity: "success",
              });
            }
          } else {
            setBtnMessage({
              open: true,
              message: data?.message,
              severity: "error",
            });
          }
        })
        .catch(() => {
          setBtnMessage({
            open: true,
            message: cachedT("serverExceptionMessage"),
            severity: "error",
          });
        })
        .finally(() => setLoading(false));
    },
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    formik.handleChange(event);
    
    if (name === "inviter") {
      formik.setFieldValue("inviter", value);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (RefInviter) {
      formik.setFieldValue("inviter", RefInviter);
    }
  }, [RefInviter]);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setBtnMessage({
      open: false,
    });
  };

  const onLogin = () => {
    if (isMobile) {
      toDownload()
      setTimeout(() => {
        toDownload()
      }, 1000);
      return;
    }
    window.open('https://app.adjust.com/176ugajm');
  };

  const handleDialogClose = () => {
    setBtnFinMessage({
      open: false,
    });
    history.push("/download");
  };

  return (
    <Flex
      className={styles.loginForm}
      mx={mdDown ? 4 : 0}
      my={mdDown ? 2 : 0}
      column
      style={{
        width: mdDown ? "100%" : "448px",
        height: "100vh",
        overflowX: "hidden",
        scrollbarWidth: 'none',
        msOverflowStyle: 'none'
      }}
    >
      <form className={styles.surround} onSubmit={formik.handleSubmit}>
        <Flex className={styles.surround} column>
          <LogoTextSvg
            onClick={() => history.push("/")}
            style={{ width: 117, cursor: "pointer" }}
          />
        </Flex>
        <Flex className={styles.surround} mt={mdDown ? 0 : 10} column>
          <Text
            color={"black"}
            style={{
              fontWeight: "700",
              fontSize: "40px",
            }}
          >
            {cachedT("Register")}
          </Text>
        </Flex>
        {/* <Flex className={styles.surround} column pt={6}>
          <Text className={styles.label} pb={1}>
            {cachedT("Username")}
          </Text>
          <FormControl>
            <OutlinedInput
              id="username"
              name="username"
              placeholder={cachedT("placeholderUsername")}
              className={styles.root}
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
            />
            <FormHelperText style={{ color: Colors.inputErrorColor }}>
              {formik.touched.username && formik.errors.username}
            </FormHelperText>
          </FormControl>
        </Flex> */}
        {/* <Flex
          className={styles.surround}
          column
          pt={formik.touched.username ? 3 : 6}
        >
          <Text className={styles.label} pb={1}>
            {cachedT("Password")}
          </Text>
          <FormControl>
            <OutlinedInput
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder={cachedT("placeholderPassword")}
              className={styles.root}
              value={formik.values.password}
              onChange={formik.handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={cachedT("togglePasswordVisibility")}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              error={formik.touched.password && Boolean(formik.errors.password)}
            />
            <FormHelperText style={{ color: Colors.inputErrorColor }}>
              {formik.touched.password && formik.errors.password}
            </FormHelperText>
          </FormControl>
        </Flex> */}
        {/* <Flex
          className={styles.surround}
          column
          pt={formik.touched.password ? 3 : 6}
        >
          <Text className={styles.label} pb={1}>
            {cachedT("confirmPasswordMessage")}
          </Text>
          <FormControl>
            <OutlinedInput
              id="confirmPassword"
              name="confirmPassword"
              type={showPassword ? "text" : "password"}
              placeholder={cachedT("placeholderPassword2")}
              className={styles.root}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={cachedT("togglePasswordVisibility")}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
            />
            <FormHelperText style={{ color: Colors.inputErrorColor }}>
              {formik.touched.confirmPassword && formik.errors.confirmPassword}
            </FormHelperText>
          </FormControl>
        </Flex> */}
        <Flex
          className={styles.surround}
          column
          pt={formik.touched.confirmPassword ? 3 : 6}
        >
          <Text className={styles.label} pb={1}>
            {cachedT("invitedBy")}
          </Text>
          <FormControl>
            <OutlinedInput
              id="inviter"
              name="inviter"
              placeholder={cachedT("referralCodeMessage")}
              className={styles.root}
              value={formik.values.inviter}
              onChange={handleInputChange}
              error={formik.touched.inviter && Boolean(formik.errors.inviter)}
            />
            <FormHelperText style={{ color: Colors.inputErrorColor }}>
              {formik.touched.inviter && formik.errors.inviter}
            </FormHelperText>
          </FormControl>
        </Flex>
        <Flex
          className={styles.surround}
          column
          pt={formik.touched.inviter ? 3 : 6}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            style={{
              boxShadow: "none",
              padding: "8px 0",
              borderRadius: "80px",
              fontSize: "18px",
              background: "#3B3C45",
              textTransform: "none",
            }}
            onClick={privyLogin}
          >
            {loading && <CircularProgress size={20} color={"inherit"} />}&nbsp;
            {t('Register')}
          </Button>
          <Flex pt={4} fontSize={12} cursorPointer style={{ textDecoration: 'underline', marginBottom: "10px" }} 
              center color={"black"}  onClick={onLogin}>
            {t('AlreadyHaveLogin')}
          </Flex>
        </Flex>
      </form>
      {/* Snackbar */}
      <AlertMessage
        open={btnMessage.open}
        message={btnMessage.message}
        severity={btnMessage.severity}
        onClose={handleSnackbarClose}
      />
      {/* Snackbar */}
      {/* Success */}
      <Dialog fullScreen={mdDown} open={btnFinMessage.open}>
        <Flex pt={4} color={"#00BA00"} center>
          <CheckCircleIcon
            style={{
              width: 60,
              height: 60,
            }}
          />
        </Flex>
        <Flex px={10} pb={4} column>
          <Flex mb={10} mt={5}>
            {btnFinMessage.message}
          </Flex>
          <Flex center>
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "4px 60px",
                borderRadius: "80px",
                fontSize: "14px",
                background: "#3B3C45",
              }}
              onClick={handleDialogClose}
            >
              {cachedT("btnConfirm")}
            </Button>
          </Flex>
        </Flex>
      </Dialog>
      {/* Snackbar */}
    </Flex>
  );
};

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    loginForm: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    surround: {
      width: "100%",
    },
    label: {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#232429",
    },
    root: {},
  })
);

export default observer(RegisterForm);
