import { useMediaQuery } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import NotiStack, { NotiMessage } from 'components/common/NotiStack';
import { observer } from 'mobx-react';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Router, Switch, Redirect } from 'react-router';
import LoadingFallback from 'screens/LoadingFallback';
import { createAppTheme } from 'styles/theme';
import history from './appBrowserHistory';
import useDepsContainer from 'hooks/useDepsContainer';
import LoadingPage from 'components/loading/LoadingPage';
import TermsPage from 'screens/Terms/TermsPage';
import PrivacyPage from 'screens/Terms/PrivacyPage';
import CookiePolicyPage from 'screens/Terms/CookiePolicyPage';
import GuildeLinesPage from 'screens/Terms/GuildeLinesPage';
import UserDataPage from 'screens/Terms/UserDataPage';
import LoginPage from 'screens/Auth/LoginPage';
import RegisterPage from 'screens/Auth/RegisterPage';
import DownloadPage from 'screens/Auth/DownloadPage';
import InvitePage from 'screens/Invite/InvitePage';
import MallPage from 'screens/Mall/MallPage';
import PaymentPage from 'screens/Payment/PaymentPage';
import TitleUpdater from './seo/TitleUpdater';
import MetaThemeColor from './seo/MetaThemeColor';

const HomePage = lazy(() => import('screens/Home/HomePage'));
const TechnologyPage = lazy(() => import('screens/Technology/TechnologyPage'));
const WhyUsPage = lazy(() => import('screens/WhyUs/WhyUsPage'));
const SuperlikePage = lazy(() => import('screens/Superlike/SuperlikePage'));
const BrandPage = lazy(() => import('screens/Brand/BrandPage'));
const NotFoundPage = lazy(() => import('screens/NotFound'));

const Activity1 = lazy(() => import('screens/Activity/Activity1/Home'));

var _NotiStackInstance: any = {};

export const NotiStackInstance = {
  push: (message: NotiMessage) => {
    _NotiStackInstance && _NotiStackInstance.push(message);
  }
};

function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
  return <Route {...rest} render={props => (isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />)} />;
}

function LoginInRoute({ component: Component, isAuthenticated, ...rest }) {
  return <Route {...rest} render={props => (isAuthenticated ? <Redirect to="/" /> : <Component {...props} />)} />;
}

const RootContainer = observer(() => {
  const { uiStore } = useDepsContainer();

  return (
    <Router history={history}>
      <Suspense fallback={<LoadingFallback />}>
        {uiStore.languageLoading && <LoadingPage />}
        <TitleUpdater />
        <MetaThemeColor />
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/technology">
            <TechnologyPage />
          </Route>
          <Route exact path="/superlike">
            <SuperlikePage />
          </Route>
          <Route exact path="/whyus">
            <WhyUsPage />
          </Route>
          <Route exact path="/brand">
            <BrandPage />
          </Route>
          <Route exact path="/terms">
            <TermsPage />
          </Route>
          <Route exact path="/privacy">
            <PrivacyPage />
          </Route>
          <Route exact path="/cookie">
            <CookiePolicyPage />
          </Route>
          <Route exact path="/guidelines">
            <GuildeLinesPage />
          </Route>
          <Route exact path="/download">
            <DownloadPage />
          </Route>
          <Route exact path="/userdata">
            <UserDataPage />
          </Route>
          <Route exact path="/activity/activity1">
            <Activity1 />
          </Route>
          <PrivateRoute path="/mall" component={MallPage} isAuthenticated={uiStore.isLogin} />
          <PrivateRoute path="/payment" component={PaymentPage} isAuthenticated={uiStore.isLogin} />
          <LoginInRoute path="/login" component={LoginPage} isAuthenticated={uiStore.isLogin} />
          <LoginInRoute path="/register" component={RegisterPage} isAuthenticated={uiStore.isLogin} />
          <Route exact path="/:username">
            <InvitePage />
          </Route>
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
        <NotiStack
          ref={(ref: any) => {
            _NotiStackInstance = ref;
          }}
        />
      </Suspense>
    </Router>
  );
});

const App = React.memo(() => {
  const theme = createAppTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { uiStore } = useDepsContainer();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RootContainer />
    </ThemeProvider>
  );
});

export default App;
