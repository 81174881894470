import { observer } from "mobx-react";
import { AppTheme, Colors } from "styles/theme";
import React, { useState, useMemo, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Flex from "components/common/Flex";
import useDepsContainer from "hooks/useDepsContainer";
import { FrameSvg } from "assets/icons";
import _ from "lodash";

function InfoPrompts({ profile, tagsLangItem }) {
  const theme = useTheme();
  const styles = useStyles();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();

  const { uiStore } = useDepsContainer();
  const { t } = useTranslation();
  const cachedT = useMemo(() => t, [t, uiStore.language]);

  const [mySignatures, setMySignatures] = useState<any[]>([]);

  useEffect(() => {
    setMySignatures(profile?.mySignatures || []);
  }, []);

  function toPrompts(id) {
    const mySignatures = tagsLangItem?.data?.mySignatures || [];
    const aboutMeValues = mySignatures.filter((item) =>
      _.includes(id, item.id)
    );
    if (!aboutMeValues?.length) {
      return cachedT("PromptsIntroduce");
    }

    return aboutMeValues[0].desc;
  }

  return (
    <Flex width={"100%"} className={styles.label} color={"#232429"} column>
      <Flex className={styles.h3} component={"h3"}>
        {cachedT("Prompts")}
      </Flex>
      {mySignatures.map((item, index) => {
        return (
          <Flex
            className={styles.prompts}
            py={2}
            px={2}
            mt={2}
            key={index}
          >
            <Flex>
              <FrameSvg />
            </Flex>
            <Flex pl={3} column>
              <Flex fontWeight={700}>{toPrompts(item.signatureId)}</Flex>
              <Flex color={"#6B6D7B"}>{item.signatureText}</Flex>
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    label: {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
    },
    h3: {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#6B6D7B",
    },
    prompts: {
      background: "#FDEBE7",
      borderRadius: "8px",
    },
  })
);

export default observer(InfoPrompts);
