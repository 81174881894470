import backendHttpClient from "./httpClient";

export const subscribeEmail = async (params) => {
  return await backendHttpClient.get(`/settings/subscribe`, params);
};

export const accountLogin = async (params) => {
  return await backendHttpClient.post(`/login`, params)
}

export const accountRegister = async (params) => {
  return await backendHttpClient.post(`/register`, params)
}

export const accountPreviewRegister = async (params) => {
  return await backendHttpClient.post(`/p1/register`, params)
}

export const accountPrivyPreviewRegister = async (params) => {
  return await backendHttpClient.post(`/pre/registration/inviter`, params)
}

export const inviteUU = async username => {
  return await backendHttpClient.get(`/v2/uu/findByConciseUserId/${username}`);
};

export const tagUU = async (language) => {
  return await backendHttpClient.get(`/u/tag?language=${language}`)
}

export const userCheck = async (conciseUserId) => {
  return await backendHttpClient.get(`/uu/check?conciseUserId=${conciseUserId}`)
}

