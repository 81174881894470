// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCBb0psTpQQgF9u0exKPTufCkP0HKHPo7o",
    authDomain: "pumpkin-date.firebaseapp.com",
    projectId: "pumpkin-date",
    storageBucket: "pumpkin-date.appspot.com",
    messagingSenderId: "1030502863190",
    appId: "1:1030502863190:web:b2425468ecb95ce40b86f7",
    measurementId: "G-HXKD084MYS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function FirebaseConfig() {
    return null;
}

export default FirebaseConfig;