import { useMediaQuery, useTheme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { LogoTextSvg } from 'assets/icons';
import PageFooter from 'components/Footer';
import Header from 'components/Header';
import Flex from 'components/common/Flex';
import Text from 'components/common/Text';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { AppTheme } from 'styles/theme';

interface PrivacyPageProps {}

const PrivacyPage = (props: PrivacyPageProps) => {
  const styles = useStyles(props);
  const history = useHistory();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Flex
      width="100vw"
      height={'100vh'}
      className={styles.pageContainer}
      bgcolor={'rgba(255, 255, 255)'}
      column
      centerY
    >
      <Header bgcolor={'rgba(255, 255, 255)'} />

      <Flex width={'100%'} flex={1} column mb={mdDown ? 0 : 15} py={4} px={mdDown ? 6 : 2} maxWidth={1200}>
        <Flex width={'100%'} justifyContent={'flex-end'}>
          <LogoTextSvg style={{ width: 120 }} />
        </Flex>

        <Text
          style={{
            fontWeight: 700,
            fontSize: 48
          }}
          color={`#E85130`}
        >
          Privacy Policy for Pumpkin Dating App
        </Text>
        <Text my={1} color="#5F606D">
          Effective date: 10th August 2023
        </Text>

        <Text
          component={'span'}
          color="#18181B"
          whiteSpace={'break-spaces'}
          mt={2}
          style={{ textAlign: 'justify' }}
          className={styles.aStyle}
        >
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            Introduction
          </Text>
          <Text mt={4}>
            Welcome to Pumpkin, the premier dating app committed to providing you a secure and enjoyable platform to
            forge meaningful connections. This Privacy Policy outlines the comprehensive procedures governing the
            collection, usage, sharing, and safeguarding of your personal and sensitive user data. By utilising Pumpkin,
            you signify your understanding and consent to the practices detailed in this policy. We suggest you read
            this in conjunction with our{' '}
            <a target="_blank" href="https://www.pumpkin.date/terms">
              Terms of Use.
            </a>
          </Text>
          {`
The App and Sites are operated by the “Pumpkin Global” (also referred to in this policy as “we” or “us”) which includes Pumpkin Global Limited, all of which are controllers of personal information collected and processed through the Pumpkin App and Sites.

The Pumpkin Global has designated a Data Protection Officer and they can be reached by emailing DPO@pumpkin.xyz.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            Data Collection
          </Text>
          <Text mt={4} style={{ fontWeight: 'bold', fontSize: 16 }}>
            App data
          </Text>
          {`
a. Personal Information: Upon creating a Pumpkin account, we collect essential personal data including but not limited to your username, wallet address, age, gender, and location. Additionally, you may opt to enrich your profile by sharing supplementary information like interests, photos, and a self-summary.

b. Sensitive Information: Pumpkin may solicit sensitive information such as sexual orientation, religious beliefs, and ethnicity, exclusively if provided by you within your profile.

c. App Usage Data: Our system captures comprehensive data concerning your interactions with the app, comprising messages, matches, preferences, and search history. This encompasses device specifics, IP addresses, browser type, and operating system particulars.

`}
          <Text mt={4} style={{ fontWeight: 'bold', fontSize: 16 }}>
            Third-party service
          </Text>
          {`
The app does use third-party services that may collect information used to identify you.

Link to the privacy policy of third-party service providers used by the app:
          `}
          <Text mb={8}>
            <a target="_blank" href="https://www.google.com/policies/privacy/">
              * Google Play Services
            </a>{' '}
            <br />
            <a target="_blank" href="https://firebase.google.com/policies/analytics">
              * Google Analytics for Firebase
            </a>{' '}
            <br />
            <a target="_blank" href="https://firebase.google.com/support/privacy/">
              * Firebase Crashlytics
            </a>{' '}
            <br />
          </Text>
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            Log Data
          </Text>
          {`

We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilising our Service, the time and date of your use of the Service, and other statistics.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            Cookies
          </Text>
          {`

Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            Service Providers
          </Text>
          {`

We may employ third-party companies and individuals due to the following reasons:

  * To facilitate our Service;
  * To provide the Service on our behalf;
  * To perform Service-related services; or
  * To assist us in analysing how our Service is used.

We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            Security
          </Text>
          {`

We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            Links to Other Sites
          </Text>
          {`

This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            Children’s Privacy
          </Text>
          {`
          
These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 years of age. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do the necessary actions.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            Changes to This Privacy Policy
          </Text>
          {`

We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            Utilisation of Data
          </Text>
          {`
          
a. Optimised Matching: Pumpkin employs your data to refine matchmaking algorithms, enhancing the compatibility of potential connections by analysing your preferences, interests, and usage patterns.

b. Communication Facilitation: Your contact details are employed for the purpose of sending service-related notifications, essential updates, and promotional material. Your communication preferences can be managed within your account settings.

c. Research and Analysis: Aggregated and anonymized data assists us in conducting research and analysis, bolstering app features and augmenting user experiences, while ensuring individual privacy is upheld.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            Data Sharing
          </Text>
          {`
          
a. Third-Party Collaboration: Certain data may be shared with trusted third-party service providers facilitating essential functions such as analytics, marketing, and customer support. Such providers are strictly bound by confidentiality obligations.

b. Legal Compliance: Pumpkin reserves the right to disclose user data in instances of legal obligation, court orders, government mandates, or to enforce our rights and policies.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            Data Security Measures
          </Text>
          {`
          
a. Advanced Encryption: Industry-standard encryption protocols are diligently implemented to secure your data during transmission and storage, thus mitigating unauthorised access.

b. Access Control: Data access is restricted solely to authorised personnel with a legitimate need and is regularly subjected to rigorous audits.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            Data Retention and Eradication
          </Text>
          {`
          
a. Retention Duration: User data is retained for the duration of active account usage or as necessitated by the provision of our services. Subsequently, selective data may be preserved for legal, regulatory, and business purposes.

b. Deletion Process: Account and associated data deletion can be requested, and upon verification, your data will be expunged within a reasonable timeframe, adhering to best practices.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            User Control and Rights
          </Text>
          {`
          
a. Profile Visibility Management: Users possess granular control over the visibility of their profiles and the data shared therein.

b. Data Access and Rectification: Users retain the right to access, rectify, or erase their personal data. Account modifications can be executed through account settings, and inquiries can be directed to our support team.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            Child Privacy Protection
          </Text>
          {`
          
Pumpkin is an exclusively adult-oriented platform, strictly intended for users aged 18 and above. We do not intentionally solicit or store data from individuals under 18.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            Policy Amendments
          </Text>
          {`
          
This Privacy Policy may be periodically updated. Significant alterations shall be communicated to users, accompanied by the requisite consent procedures as per applicable regulations.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            Contact Information
          </Text>
          {`
          
Should you have any inquiries, concerns, or requests regarding your data and privacy, our Data Protection Officer (DPO) can be reached at dpo@pumpkin.xyz.

By utilising Pumpkin, you signify your comprehension and consent to this Privacy Policy, encompassing the collection, usage, and sharing of your data as expounded herewith.

`}
        </Text>
      </Flex>

      <Flex className={styles.footerWrap} mt={mdDown ? 8 : 1.6}>
        <PageFooter isHome />
      </Flex>
    </Flex>
  );
};

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    pageContainer: {
      overflowX: 'hidden',
      overflowY: 'auto'
    },
    sessionWrap: {
      width: '100%'
    },
    footerWrap: {
      width: '100%'
    },
    aStyle: {
      '& a': {
        color: '#E85130'
      }
    }
  })
);

export default observer(PrivacyPage);
