import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import Flex from "components/common/Flex";
import Text from "components/common/Text";
import { observer, useLocalStore } from "mobx-react";
import { AppTheme } from "styles/theme";
import { useTranslation } from "react-i18next";
import { EveryOneIcon, MatchMakersIcon, LogoFrametSvg } from "assets/icons";
import { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import ObserverLoadingContainer from "components/common/ObserverLoadingContainer";
import PubStepDots from "./PubStepDots";
import useDepsContainer from "hooks/useDepsContainer";

const STEP_CHANGING_TIME = 5000;

interface FinanceProps {}

const Finance = (props: FinanceProps) => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const { uiStore } = useDepsContainer();
  const { t, i18n } = useTranslation();
  const cachedT = useMemo(() => t, [t, uiStore.language]);
  const cachedI18n = useMemo(() => i18n, [i18n]);

  const state = useLocalStore(() => ({
    activeStep: 1,
    direction: "",
  }));
  const intervalRef: any = useRef();
  const styles = useStyles({
    ...props,
    activeStep: state.activeStep,
    direction: state.direction,
    mdDown,
  });

  const intervalSteps = () => {
    // state.activeStep = state.activeStep < 3 ? state.activeStep + 1 : 1;
  };

  useLayoutEffect(() => {
    intervalRef.current = setInterval(intervalSteps, STEP_CHANGING_TIME);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <ObserverLoadingContainer once id="finance-container">
      <Flex width="100%" id={"finance"} column alignItems="flex-center">
        <Flex
          column
          pt={mdDown ? 4 : 10}
          pl={mdDown ? 4 : 10}
          pr={mdDown ? 4 : 10}
          mb={mdDown ? 6 : 0}
        >
          <Flex width={"100%"} center={mdDown}>
            {state.activeStep === 1 && (
              <>
                <Text
                  className={styles.titleText}
                  style={{
                    background:
                      "linear-gradient(90deg, #FD746C 0%, #FF9068 100%)",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {cachedT("Privacy")}&nbsp;
                </Text>
                <Text className={styles.titleText} color={"white"}>
                  {cachedT("toggleVisibility")}:
                </Text>
              </>
            )}
            {state.activeStep === 2 && (
              <>
                <Text
                  className={styles.titleText}
                  style={{
                    background:
                      "linear-gradient(90deg, #FD746C 0%, #FF9068 100%)",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {cachedT("Reputation")}&nbsp;
                </Text>
                <Text className={styles.titleText} color={"white"}>
                  {cachedT("isEverything")}
                </Text>
              </>
            )}
            {state.activeStep === 3 && (
              <>
                <Text className={styles.titleText} color={"white"}>
                  {cachedT("freeFlow")}&nbsp;
                </Text>
                <Text
                  className={styles.titleText}
                  style={{
                    background:
                      "linear-gradient(90deg, #FD746C 0%, #FF9068 100%)",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {cachedT("Finance")}
                </Text>
              </>
            )}
          </Flex>
          <Flex width={"100%"} center mt={2.25} column>
            <Flex>
              <Text
                className={styles.subText}
                color={"subTextColor3"}
                textAlign={"left"}
              >
                {state.activeStep === 1 && cachedT(`dataPrivacy`)}
                {state.activeStep === 2 && cachedT(`reputationCriteria`)}
                {state.activeStep === 3 && cachedT(`financialTools`)}
              </Text>
            </Flex>
          </Flex>
          <Flex width={"100%"} justifyContent={"space-between"} mt={2.25}>
            <Flex>
              <PubStepDots
                activeStep={state.activeStep}
                direction={state.direction}
              />
            </Flex>
            <Flex
              mr={3}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (state.activeStep === 3) {
                  state.direction = "";
                  state.activeStep = 1;
                  clearInterval(intervalRef.current);
                  intervalRef.current = setInterval(
                    intervalSteps,
                    STEP_CHANGING_TIME
                  );
                  return;
                }

                if (state.activeStep === 1) {
                  if (state.activeStep === 1) {
                    state.direction = "-";
                  } else {
                    state.direction = "";
                  }
                  state.activeStep = 2;
                  clearInterval(intervalRef.current);
                  intervalRef.current = setInterval(
                    intervalSteps,
                    STEP_CHANGING_TIME
                  );
                  return;
                }

                if (state.activeStep === 2) {
                  state.direction = "-";
                  state.activeStep = 3;
                  return;
                }
              }}
            >
              <LogoFrametSvg width={32} height={32} />
            </Flex>
          </Flex>
          {/* === */}
          <img
            src={"/images/ply1-mobile-min.png"}
            style={{ display: "none" }}
          />
          <img
            src={"/images/ply2-mobile-min.png"}
            style={{ display: "none" }}
          />
          <img
            src={"/images/ply3-mobile-min.png"}
            style={{ display: "none" }}
          />
          <img src={"/images/ply1-min.png"} style={{ display: "none" }} />{" "}
          <img src={"/images/ply2-min.png"} style={{ display: "none" }} />
          <img src={"/images/ply3-min.png"} style={{ display: "none" }} />
          {/* ===== */}
        </Flex>

        {!mdDown && (
          <Flex
            column
            style={{
              width: "50%",
              height: "70vh",
              overflow: "hidden",
              position: "absolute",
              bottom: 0,
            }}
          >
            {state.activeStep === 1 && (
              <Flex className={styles.graphicWraper} centerY>
                <Box
                  className="image"
                  component={"img"}
                  src={"/images/ply1-min.png"}
                  height="70vh"
                />
              </Flex>
            )}
            {state.activeStep === 2 && (
              <Flex className={styles.graphicWraper} center>
                <Box
                  className="image"
                  component={"img"}
                  src={"/images/ply2-min.png"}
                  height="60vh"
                />
              </Flex>
            )}
            {state.activeStep === 3 && (
              <Flex className={styles.graphicWraper} center>
                <Box
                  className="image"
                  component={"img"}
                  src={"/images/ply3-min.png"}
                  height="70vh"
                />
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </ObserverLoadingContainer>
  );
};

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    titleText: {
      fontFamily: "'Inter' !important",
      fontStyle: "normal !important",
      fontWeight: "700 !important",
      fontSize: (props: any) =>
        props.mdDown ? "32px !important" : "40px !important",
      lineHeight: (props: any) =>
        props.mdDown ? "40px !important" : "48px !important",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      transition: "all 0.3s ease-in-out",
    },
    subText: {
      fontFamily: "'Inter' !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      fontSize: (props: any) =>
        props.mdDown ? "16px !important" : "18px !important",
      lineHeight: "24px !important",
    },
    stepTitle: {
      fontFamily: "'Inter' !important",
      fontStyle: "normal !important",
      fontWeight: "700 !important",
      fontSize: (props: any) =>
        props.mdDown ? "18px !important" : "20px !important",
      lineHeight: (props: any) =>
        props.mdDown ? "24px !important" : "28px !important",
      letterSpacing: "-0.3px !important",
      transition: "all 0.3s ease-in-out",
    },
    stepDesc: {
      fontFamily: "'Inter' !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      fontSize: (props: any) =>
        props.mdDown ? "14px !important" : "16px !important",
      lineHeight: (props: any) =>
        props.mdDown ? "20px !important" : "24px !important",
      color: "#6B6D7B !important",
      transition: "all 0.3s ease-in-out",
    },
    graphicWraper: {
      transition: "all 0.3s ease-in-out",
      "& .image": {
        animation: "$graphic_appear 0.4s ease-in-out",
        width: "auto",
        // maxWidth: 360,
      },
    },
    "@keyframes graphic_appear": {
      "0%": {
        opacity: 0,
        transform: "translate(80px, -50px)",
      },
      "100%": {},
    },
    stepDot: {
      "& .position-1": {
        transition: `all 0.4s ease-in-out`,
        animation: (props: any) =>
          props.activeStep === 1 && props.direction === ""
            ? "$step_moving_down 0.4s ease-in-out"
            : "",
      },
      "& .position-2": {
        transition: `all 0.4s ease-in-out`,
        animation: (props: any) =>
          props.activeStep === 2 && props.direction === ""
            ? "$step_moving_down 0.4s ease-in-out"
            : "",
      },
      "& .position-3": {
        animation: "$step_moving_up 0.4s ease-in-out",
      },
    },
    "@keyframes step_moving_up": {
      "0%": {
        opacity: 0,
        transform: "translateY(-100px)",
      },
      "100%": {},
    },
    "@keyframes step_moving_down": {
      "0%": {
        opacity: 0,
        transform: "translateY(100px)",
      },
      "100%": {},
    },
  })
);

export default observer(Finance);
