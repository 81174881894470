import { observer } from "mobx-react";
import { AppTheme, Colors } from "styles/theme";
import React, { useState, useMemo, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Flex from "components/common/Flex";
import Text from "components/common/Text";
import useDepsContainer from "hooks/useDepsContainer";
import _ from "lodash";

function InfoTags({ profile, tagsLangItem }) {
  const theme = useTheme();
  const styles = useStyles();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();

  const { uiStore } = useDepsContainer();
  const { t } = useTranslation();
  const cachedT = useMemo(() => t, [t, uiStore.language]);

  const [moreAboutMe, setMoreAboutMe] = useState<{ title: string, id: string }[]>([]);
  const [myInterests, setMyInterests] = useState<any>({});
  const [myLanguages, setMyLanguages] = useState<any>({});
  const [mySignatures, setMySignatures] = useState<any>({});
  const [myPurposes, setMyPurposes] = useState<any>({});

  useEffect(() => {
    const translator = tagsLangItem?.data || {};
    setMoreAboutMe(
      toMoreAboutMe(profile?.moreAboutMe, translator?.moreAboutMe)
    );
    setMyInterests(toUniversal(profile?.myInterests, translator?.myInterests));
    setMyLanguages(toUniversal(profile?.myLanguages, translator?.myLanguages));
    setMySignatures(
      toUniversal(profile?.mySignatures, translator?.mySignatures)
    );
    setMyPurposes(toUniversal(profile?.myPurposes, translator?.myPurposes));
  }, []);

  function toMoreAboutMe(data, translator) {
    const aboutMeKeys = Object.keys(data || []);
    const aboutMeValues = _.flatMap(aboutMeKeys, (key) => data[key]);
    const descArray = _.flatMap(translator, "details");
    const moreAboutMe = _.chain(descArray)
      .filter((item) => _.includes(aboutMeValues, item.id))
      .map("desc")
      .value();

    const id = _.flatMap(aboutMeKeys, (id) => { return { id }; })
    const title = _.flatMap(moreAboutMe, (title) => { return { title } })
    const result = _.merge(id, title)
    return result;
  }

  function toUniversal(data, translator) {
    const aboutMeValues = data || [];
    const moreMe = _.chain(translator)
      .filter((item) => _.includes(aboutMeValues, item.id))
      .map("desc")
      .value();

    return moreMe;
  }

  function MoreAboutMeIcon({ id }) {
    let type = "";
    switch (id) {
      case "64745777cea81015deeaf604": type = "fitness"; break;
      case "6474579900093c2a37fa595e": type = "drinking"; break;
      case "647d53dc00e93e783cbb381c": type = "smoking"; break;
      case "647d540e00e93e783cbb381d": type = "diet"; break;
      case "647d541a00e93e783cbb381e": type = "sleep"; break;
      case "647d542600e93e783cbb381f": type = "education"; break;
      case "647d543200e93e783cbb3820": type = "child"; break;
      case "647d547400e93e783cbb3821": type = "constellation"; break;
      case "647d548400e93e783cbb3822": type = "personality"; break;
    }
    return <img src={`/images/aboutme/icon_profile_${type}_light.png`} style={{ width: "14px" }} />
  }

  function ItemTags({ data, title, typeName, mt }) {
    return data.length ? (
      <Flex width={"100%"} mt={mt} column>
        <Flex mb={4} className={styles.title}>
          {title}
        </Flex>
        <Flex width={"100%"} className={styles.col}>
          {data.map((item, index) => {
            return typeName === "moreAboutMe" ? (
              <Flex
                className={styles.item}
                mr={1}
                mb={1}
                key={index}
                centerY
              >
                <Flex mr={1}>
                  <MoreAboutMeIcon id={item.id} />
                </Flex>
                <Flex>{item.title}</Flex>
              </Flex>
            ) : (
              <Flex
                className={styles.item}
                mr={1}
                mb={1}
                key={item + index}
                centerY
              >
                {item}
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    ) : null;
  }

  return (
    <Flex width={"100%"} column>
      <ItemTags
        mt={4}
        data={mySignatures}
        typeName="mySignatures"
        title={cachedT("mySignatures")}
      />
      <ItemTags
        mt={4}
        data={myPurposes}
        typeName="myPurposes"
        title={cachedT("myPurposes")}
      />
      <ItemTags
        mt={4}
        data={myLanguages}
        typeName="myLanguages"
        title={cachedT("myLanguages")}
      />
      <ItemTags
        mt={4}
        data={myInterests}
        typeName="myInterests"
        title={cachedT("myInterests")}
      />
      <ItemTags
        mt={4}
        data={moreAboutMe}
        typeName="moreAboutMe"
        title={cachedT("moreAboutMe")}
      />
    </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    title: {
      fontWeight: 700,
      fontSize: "14px",
      color: "#6B6D7B",
    },
    col: {
      display: "flex",
      flexWrap: "wrap",
    },
    item: {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "16px",
      borderRadius: "24px",
      backgroundColor: "#F3F4FC",
      color: "black",
      padding: "4px 16px",
    },
  })
);

export default observer(InfoTags);
