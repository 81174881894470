import { LangKeys } from "locales/i18n";
import { action, makeObservable, observable } from "mobx";
import BaseStore from "./BaseStore";

class UIStore extends BaseStore {
  @observable public isGlobalLoading = false;
  @observable public languageLoading = false;
  @observable public useAnimation = true;
  @observable public language = localStorage.getItem("@lang") || LangKeys.en;
  @observable public isLogin = !!localStorage.getItem("token");
  @observable public loginModal = {
    isShow: false,
  };
  @observable public lastPricesToken = {};

  constructor(depsContainer: any) {
    super(depsContainer);
    makeObservable(this);
  }

  public getStoreLang() {
    if (this.language === 'cs') {
      return "zh_CN"
    } else if (this.language === 'ct') {
      return "zh_TW"
    } else if (this.language === 'vi' || this.language === 'vn') {
      return "vi_VN"
    } else if (this.language === 'jp') {
      return "ja_JP"
    } else if (this.language === 'kr') {
      return "ko_KR"
    } else if (this.language === 'in') {
      return "id_ID"
    } else if (this.language === 'en' || this.language === 'sp') {
      return "en_US"
    } else {
      return "en_US"
    }
  }
}

export default UIStore;
